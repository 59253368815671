import {ApolloClient, ApolloLink, from, HttpLink, split} from "@apollo/client";
import {getMainDefinition} from "@apollo/client/utilities";

import {onError} from "@apollo/client/link/error";

import apolloLogger from "apollo-link-logger";
import createCache from "./createCache";

const httpLink = new HttpLink({
  uri: "/graphql",
  credentials: "include"
});

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
// const splitLink = split(({ query }) => {
//   const definition = getMainDefinition(query);
//   return (
//     definition.kind === "OperationDefinition" &&
//     definition.operation === "subscription"
//   );
// }, httpLink);

const logLink = new ApolloLink((operation, forward) => {
  console.info('request', operation.getContext());
  return forward(operation).map((result) => {
    console.info('response', operation.getContext());
    return result;
  });
});

const link = from([
  //...(__DEV__ ? [apolloLogger] : []),
  apolloLogger,
  logLink,
  onError(({ response, graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) console.log("graphQLErrors", graphQLErrors);
    if (networkError) console.log("networkError", networkError);
    if (response) console.log("response", response);
    if (operation) console.log("operation", operation);
    graphQLErrors &&
      graphQLErrors.map(({ message, locations, path }) =>
        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.warn(`[Network error]: ${networkError}`);

    // console.log('response',response);
  }),
  httpLink,
  //splitLink
]);

const cache = createCache();

export default function createApolloClient() {
  return new ApolloClient({
    link,
    cache: cache.restore(window.App.apolloState),
    queryDeduplication: true,
    connectToDevTools: true
    // addTypename: false,
    // defaultOptions: {
    //   watchQuery: {
    //     fetchPolicy: 'cache-and-network',
    //     errorPolicy: 'ignore',
    //   },
    //   query: {
    //     fetchPolicy: 'cache-first',
    //     errorPolicy: 'all',
    //   },
    //   mutate: {
    //     errorPolicy: 'all',
    //   },
    // },
  });
}
