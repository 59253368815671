import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import StyleContext from "isomorphic-style-loader/StyleContext";
import { HelmetProvider } from "react-helmet-async";
// import FastClick from 'fastclick';
import queryString from "query-string";
// import en from 'react-intl/locale-data/en';
// import de from 'react-intl/locale-data/de';
// import { calculateResponsiveState } from "redux-responsive";
import Cookies from "universal-cookie";
import isMobile from "ismobilejs";
import "nodep-date-input-polyfill";
import { localeUrl } from "./core/url";
// import { registerPersistentTrackingListeners } from "./core/persistentTracking";
import "./core/storagePolyfill";
import router from "./router";
import history from "./core/history";
import App from "./components/App";
import configureStore from "./store/configureStore";
import { updateAlternate, updateLink, updateMeta } from "./core/DOMUtils";
import { deepForceUpdate, ErrorReporter } from "./core/devUtils";
import { inIframe, scrollTo } from "./core/utils";
import AutobahnReact from "./core/websocket";
import createApolloClient from "./core/createApolloClient/createApolloClient.client";
import { handleStag, initStag } from "./actions/affiliate";
import { SWRConfig } from "swr";
// import { initCookieControl } from './actions/cookie-control';
// import { fetchData, preloadCategories } from './actions/gamebrowser';

// const possibleLocale = navigator.language.split('-')[0] || 'en';
// addLocaleData(require(`intl/locale-data/jsonp/en-SE.js`));
// GSAP

const apolloClient = createApolloClient();
const cookies = new Cookies();
const container = document.getElementById("app");
let appInstance;
let currentLocation = history.location;
const queryParams = queryString.parse(location.search);

const store = configureStore(window.App.state, {
  history,
  AutobahnReact,
  isMobile,
});

const { stag } = queryParams;
const cookiStag = cookies.get("stagConfig");
//store.dispatch(initWebSockets());

console.log(cookiStag, "cookiStag");
if (!cookiStag) {
  if (!stag) {
    if (
      document?.referrer?.indexOf("blog") > -1 &&
      document?.referrer?.indexOf("theclubhousecasino.com") > -1
    ) {
      handleStag("155260_642ac0e9393c25a373cc390c").then((resultStag) => {
        store.dispatch(initStag(resultStag, cookies.get("stag")));
      });
    }
    // else {
    //   handleStag("113873_642ac149393c25a373cc42f7").then((resultStag) => {
    //     store.dispatch(initStag(resultStag, cookies.get("stag")));
    //   });
    //}
  } else {
    handleStag(stag).then((resultStag) => {
      store.dispatch(initStag(resultStag, cookies.get("stag")));
    });
  }
}

// calculate the initial state
// store.dispatch(calculateResponsiveState(window));
// store.dispatch(initDevice());
// store.dispatch(startLivePush());

/* eslint-disable global-require */

// Global (context) variables that can be easily accessed from any React component
// https://facebook.github.io/react/docs/context.html
const insertCss = (...styles) => {
  // eslint-disable-next-line no-underscore-dangle
  const removeCss = styles.map((x) => x._insertCss());
  return () => {
    removeCss.forEach((f) => f());
  };
};

const context = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss,
  // Initialize a new Redux store
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store,
  client: apolloClient,
  cookies,
  isMobile: isMobile(),
  isAuthenticated: !!cookies.get("isAuthenticated"),
};

// Initate the base
// store.dispatch(setVisitingCountry());

// Switch off the native scroll restoration behavior and handle it manually
// https://developers.google.com/web/updates/2015/09/history-api-scroll-restoration
const scrollPositionsHistory = {};
if (window.history && "scrollRestoration" in window.history) {
  window.history.scrollRestoration = "manual";
}

let onRenderComplete = function initialRenderComplete() {
  const elem = document.getElementById("css");
  if (elem) elem.parentNode.removeChild(elem);
  onRenderComplete = function renderComplete(route, location) {
    //document.title = route.title;

    // Update necessary tags in <head> at runtime here, ie:
    updateMeta("description", route.description);
    updateMeta("keywords", route.keywords);
    updateLink("canonical", route.canonicalUrl);
    updateLink("hreflang", route.hreflang);
    if (_.size(route.hreflang) > 0 && route.index) {
      const hreflang = {};
      Object.keys(route.hreflang).map(
        (lang) =>
          (hreflang[lang] = `https://www.theclubhousecasino.com${localeUrl(
            route.name,
            lang,
            _.omit(route.params, ["path", "locale"])
          )}`)
      );
      updateAlternate(hreflang);
    }
    // updateCustomMeta('og:url', route.canonicalUrl);
    // updateCustomMeta('og:image', route.imageUrl);

    // etc.

    let scrollX = 0;
    let scrollY = 0;
    const pos = scrollPositionsHistory[location.key];
    if (pos) {
      scrollX = pos.scrollX;
      scrollY = pos.scrollY;
    } else {
      const targetHash = location.hash.substr(1);
      if (targetHash) {
        _.delay(() => {
          const target = document.getElementById(targetHash);
          if (target) {
            scrollTo(window, 0.5, {
              scrollTo: {
                y: `#${targetHash}`,
                autoKill: false,
                offsetY: isMobile().any ? 60 : 0,
              },
              ease: Power2.easeInOut,
            });
          }
        }, 500);
      }
    }

    // Restore the scroll position if it was saved into the state
    // or scroll to the given #hash anchor
    // or scroll to top of the page
    window.scrollTo(scrollX, scrollY);
  };
};

// @depricated Make taps on links and buttons work fast on mobiles
// FastClick.attach(document.body);

// Re-render the app when window.location changes
async function onLocationChange({ location, action }) {
  // Remember the latest scroll position for the previous location
  scrollPositionsHistory[currentLocation.key] = {
    scrollX: window.pageXOffset,
    scrollY: window.pageYOffset,
  };
  // Delete stored scroll position for next page if any

  context.pathname = location.pathname;
  context.routename = location.routename;
  context.queryParams = queryParams;
  // context.query = queryParams;
  context.locale = store.getState().intl.locale;

  const processRoute = (route, location, action) => {
    // Dispatch our route, even if we don't transition it
    // store.dispatch(updateRoute({ ...route, state: location.state }));
    context.routename = route.name;

    if (action === "PUSH" || action === "REPLACE") {
      if (
        (location.state &&
          location.state.hasOwnProperty("noTransition") &&
          location.state.noTransition) ||
        route.noTransition
      ) {
        //store.dispatch(updateRoute({ ...route, state: location.state }));
        return false;
      }

      delete scrollPositionsHistory[location.key];
    }

    currentLocation = location;

    // Prevent multiple page renders during the routing process
    if (currentLocation.key !== location.key) {
      return;
    }

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    const helmetContext = {};

    context.route = route;
    appInstance = ReactDOM.hydrate(
      <HelmetProvider context={helmetContext}>
        <StyleContext.Provider value={{ insertCss }}>
          <SWRConfig
            value={{
              provider: () => new Map(),
            }}
          >
            <App context={context}>{route.component}</App>
          </SWRConfig>
        </StyleContext.Provider>
      </HelmetProvider>,
      container,
      () => onRenderComplete(route, location)
    );

    // register our service worker
    // store.dispatch(registerServiceWorker());

    // register the page to analytics
    // if (window.analytics) window.analytics.page(null, route.name);

    return route;
  };

  // Traverses the list of routes in the order they are defined until
  // it finds the first route that matches provided URL path string
  // and whose action method returns anything other than `undefined`.

  const route = await router.resolve(context);

  try {
    processRoute(route, location, action);
    // register our service worker
    // store.dispatch(registerServiceWorker());

    // register the page to analytics
    if (window.analytics) window.analytics.page(null, route.name);
  } catch (error) {
    // Display the error in full-screen for development mode
    if (__DEV__) {
      appInstance = null;
      document.title = `Error: ${error.message}`;
      ReactDOM.hydrate(<ErrorReporter error={error} />, container);
      throw error;
    }

    // window.analytics.track(`${TRACK_EVENTS.CLIENTERROR}`, { error: error });

    console.error(error); // eslint-disable-line no-console

    // Do a full page reload if error occurs during client-side navigation
    // if (action && currentLocation.key === location.key) {
    //   window.location.reload();
    // }
  }
}
// register our service worker

let isHistoryObserved = false;
export default function main() {
  // Handle client-side navigation by using HTML5 History API
  // For more information visit https://github.com/mjackson/history#readme
  currentLocation = history.location;
  if (!isHistoryObserved) {
    isHistoryObserved = true;
    history.listen(onLocationChange);
  }
  onLocationChange({ location: currentLocation });
  // .then((route) => {
  //   // checkPage(route.name);
  //   // registerPersistentTrackingListeners();
  //   // render the curacao logo on the bottom

  if (window.analytics) {
    window.analytics.ready(() => {
      if (window.Raven) {
        window.Raven.setTagsContext({ environment: process.env.NODE_ENV });
      }
    });
  }
  // store.dispatch(registerServiceWorker());
}

// globally accesible entry point
window.RSK_ENTRY = main;

// Handle errors that might happen after rendering
// Display the error in full-screen for development mode
if (__DEV__) {
  window.addEventListener("error", (event) => {
    appInstance = null;
    document.title = `Runtime Error: ${event?.error?.message}`;
    ReactDOM.render(<div />, container);
    // ReactDOM.render(<ErrorReporter error={event.error} />, container);
  });
}

// Enable Hot Module Replacement (HMR)

if (module.hot) {
  module.hot.accept(0, () => {
    if (appInstance && appInstance.updater.isMounted(appInstance)) {
      // Force-update the whole tree, including components that refuse to update
      deepForceUpdate(appInstance);
    }

    onLocationChange({ location: currentLocation });
  });
}

// const TIMEOUT = 10000;
// let lastTime = new Date().getTime();
//
// setInterval(() => {
//   const currentTime = new Date().getTime();
//   if (currentTime > lastTime + TIMEOUT + 2000) {
//     // Wake!
//     store.dispatch(checkUserSession("client.js"));
//   }
//   lastTime = currentTime;
// }, TIMEOUT);

if (inIframe()) {
  window.top.location = "/";
}
