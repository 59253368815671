// The top-level (parent) route
const routes = {
  path: "/:locale",

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: "",
      name: "home",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () => import(/* webpackChunkName: 'home' */ "./home"),
      breadcrumb: "Casino",
    }, // localization done
    {
      path: "/my-clubhouse",
      name: "myClubhouse",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'my-clubhouse' */ "./myClubhouse"),
      breadcrumb: "Casino",
      isNeedAuthorization: true,
    }, // localization done
    {
      path: "/my-clubhouse3",
      name: "myClubhouse3",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'my-clubhouse' */ "./myClubhouse"),
      breadcrumb: "Casino",
      isNeedAuthorization: true,
    }, // localization done
    {
      path: "/free-spins/:bonusTitle",
      name: "freeSpinGames",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () =>
        import(/* webpackChunkName: 'freeSpinGames' */ "./freeSpinGames"),
      breadcrumb: "Casino",
      isNeedAuthorization: true,
    },
    {
      path: "/login",
      name: "login",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () => import(/* webpackChunkName: 'login' */ "./login"),
      breadcrumb: "Login",
    }, // localization done
    {
      path: "/register",
      name: "register",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () => import(/* webpackChunkName: 'register' */ "./register"),
      breadcrumb: "Register",
    }, // localization done
    // {
    //   path: "/welcome",
    //   name: "welcome",
    //   index: true,
    //   changefreq: "weekly",
    //   priority: 0.8,
    //   load: () => import(/* webpackChunkName: 'welcome' */ "./welcome"),
    //   breadcrumb: "Welcome",
    // }, // localization done
    {
      path: "/help",
      name: "faq",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () => import(/* webpackChunkName: 'faq' */ "./faq"),
      breadcrumb: "Help",
    }, // localization done
    {
      path: "/payments",
      name: "payments",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () => import(/* webpackChunkName: 'payments' */ "./payments"),
      breadcrumb: "Payments",
    }, // localization done
    {
      path: "/:lobby(casino|lobby|aula)/:maincategory/:subcategory?",
      name: "lobby",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      load: () => import(/* webpackChunkName: 'home' */ "./home"),
      breadcrumb: "Casino",
    }, // localization done
    // {
    //   path: "/sportsbook",
    //   name: "sportsbook",
    //   index: true,
    //   changefreq: "weekly",
    //   priority: 0.8,
    //   load: () => import(/* webpackChunkName: 'sportsbook' */ "./sportsbook"),
    //   breadcrumb: "Sportsbook"
    // }, // localization done
    // {
    //   path: "/tournaments",
    //   name: "tournaments",
    //   index: true,
    //   changefreq: "weekly",
    //   priority: 0.8,
    //   load: () => import(/* webpackChunkName: 'tournaments' */ "./tournaments"),
    //   breadcrumb: "Tournaments"
    // }, // localization done
    {
      path: "/deposit/:status/:ftd?",
      name: "depositStatus",
      load: () =>
        import(
          /* webpackChunkName: 'deposit-paymentstatus' */ "./deposit/status"
        ),
      isNeedAuthorization: true,
    },
    {
      path: "/deposit",
      name: "deposit",
      load: () =>
        import(/* webpackChunkName: 'deposit-component' */ "./deposit/index"),
      isNeedAuthorization: true,
    },

    {
      path: "/game/:id/:slug?",
      name: "game",
      breadcrumb: "My game",
      load: () => import(/* webpackChunkName: 'game' */ "./game"),
    }, // localization done
    {
      path: "/:path/:tableID(\\d+)",
      name: "liveGame",
      load: () => import(/* webpackChunkName: 'game' */ "./game/live-index"),
    }, // localization done
    {
      path: "/users/password/edit/:resetToken?",
      name: "forgotPassword",
      load: () =>
        import(/* webpackChunkName: 'forgot-password' */ "./forgot-password"),
    },
    {
      path: "/profile/limits",
      name: "removeLimit",
      load: () =>
        import(/* webpackChunkName: 'remove-limit' */ "./remove-limit"),
      isNeedAuthorization: true,
    },
    /* legal section */
    {
      path: "/terms-and-conditions",
      name: "termsAndConditions",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Vilkår & Betingelser",
      load: () =>
        import(
          /* webpackChunkName: 'terms-and-conditions' */ "./legal/terms-and-conditions"
        ),
    },
    {
      path: "/bonus-terms-and-conditions",
      name: "bonusTerms",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Bonus terms & conditions",
      load: () =>
        import(/* webpackChunkName: 'bonus-terms' */ "./legal/bonus-terms"),
    },
    {
      path: "/about-us",
      name: "aboutUs",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Om TheClubHouseCasino",
      load: () => import(/* webpackChunkName: 'about-us' */ "./legal/about-us"),
    },
    // {
    //   path: "/trading-policy",
    //   name: "tradingPolicy",
    //   index: true,
    //   changefreq: "weekly",
    //   priority: 0.8,
    //   breadcrumb: "Handelsbetingelser",
    //   load: () =>
    //       import(/* webpackChunkName: 'trading-policy' */ "./legal/trading-policy")
    // },
    {
      path: "/game-rules",
      name: "gameRules",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Spilleregler",
      load: () =>
        import(/* webpackChunkName: 'game-rules' */ "./legal/game-rules"),
    },
    {
      path: "/cookie-policy",
      name: "cookiePolicy",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Cookie policy",
      load: () =>
        import(/* webpackChunkName: 'cookie-policy' */ "./legal/cookie-policy"),
    },
    {
      path: "/privacy-policy",
      name: "privacyPolicy",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Privatlivs- og Cookiepolitik",
      load: () =>
        import(
          /* webpackChunkName: 'privacy-policy' */ "./legal/privacy-policy"
        ),
    },
    {
      path: "/responsible-gaming-policy",
      name: "responsibleGamingLegal",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Ansvarligt Spil",
      load: () =>
        import(
          /* webpackChunkName: 'responsible-gaming-legal' */ "./legal/responsible-gaming"
        ),
    },

    /* account section */
    {
      path: "/account",
      breadcrumb: "Account",
      isNeedAuthorization: true,
      children: [
        // {
        //   path: "/personlige-oplysninger",
        //   name: "profile",
        //   breadcrumb: "Profile",
        //   load: () =>
        //     import(/* webpackChunkName: 'profile' */ "./account/profile")
        // },
        // {  
        //   path: "/saldo-og-kontooplysninger",
        //   name: "wallet",
        //   breadcrumb: "Wallet",
        //   load: () =>
        //     import(/* webpackChunkName: 'wallet' */ "./account/wallet")
        // },
        // {
        //   path: "/responsible-gaming",
        //   name: "responsibleGaming",
        //   breadcrumb: "Responsible gaming",
        //   load: () =>
        //     import(
        //       /* webpackChunkName: 'responsible-gaming' */ "./account/responsible-gaming"
        //     )
        // },
        {
          path: "/responsible-gaming-verify",
          name: "responsibleGamingVerify",
          breadcrumb: "Responsible gaming",
          isNeedAuthorization: true,
          load: () =>
            import(
              /* webpackChunkName: 'responsible-gaming-verify' */ "./account/responsible-gaming/verify"
            ),
        },
        {
          path: "/renew-email/:email/:key",
          name: "renewEmail",
          isNeedAuthorization: true,
          load: () =>
            import(
              /* webpackChunkName: 'account-renew-email' */ "./account/renew-email"
            ),
        },
        {
          path: "/confirmation",
          name: "confirmation",
          isNeedAuthorization: true,
          load: () =>
            import(
              /* webpackChunkName: 'confirmation' */ "./account/confirmation"
            ),
        },
      ],
    },
    /* promotion section */
    {
      path: "/promotions",
      breadcrumb: "Promotions",

      // index: true,
      // changefreq: "weekly",
      // priority: 0.8,
      // breadcrumb: "Promotions",
      // load: () =>
      //   import(
      //     /* webpackChunkName: 'promotions-overview' */ "./promotions/promotions-overview"
      //   ),
      children: [
        {
          path: "",
          name: "promotions",
          index: true,
          changefreq: "weekly",
          priority: 0.8,
          load: () =>
            import(
              /* webpackChunkName: 'promotions-overview' */ "./promotions/promotions-overview"
            ),
        }, // localization done
        {
          path: "/:slug([a-z0-9]+(?:-[a-z0-9]+)*)",
          name: "promotionDetail",
          breadcrumb: "Promotion details",
          load: () =>
            import(
              /* webpackChunkName: 'promotion-detail' */ "./promotions/promotion-detail"
            ),
        },
      ],
    },

    {
      path: "/landing-page",
      breadcrumb: "LandingPage",
      children: [
        {
          path: "",
          name: "landing",
          index: true,
          changefreq: "weekly",
          priority: 0.8,
          load: () =>
            import(
              /* webpackChunkName: 'landing-overview' */ "./landing/landing-overview"
            ),
        }, // localization done
        {
          path: "/:slug([a-z0-9]+(?:-[a-z0-9]+)*)",
          name: "landingPageDetail",
          breadcrumb: "Landingdetails",
          load: () =>
            import(
              /* webpackChunkName: 'landing-detail' */ "./landing/landing-detail"
            ),
        },
      ],
    },

    /* tournaments section */
    {
      path: "/tournaments",
      breadcrumb: "Tournaments",
      children: [
        {
          path: "",
          name: "tournaments",
          index: true,
          changefreq: "weekly",
          priority: 0.8,
          load: () =>
            import(
              /* webpackChunkName: 'tournaments-overview' */ "./tournaments/tournaments-overview"
            ),
        }, // localization done
        {
          path: "/:slug",
          name: "tournamentDetail",
          breadcrumb: "tournament details",
          load: () =>
            import(
              /* webpackChunkName: 'tournament-detail' */ "./tournaments/tournament-detail"
            ),
        },
      ],
    },
    {
      path: "/live-lobby",
      name: "liveLobby",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Live Casino",
      load: () => import(/* webpackChunkName: 'live-lobby' */ "./liveLobby"),
    },
    {
      path: "/crypto-lobby",
      name: "cryptoLobby",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Crypto lobby",
      load: () =>
        import(/* webpackChunkName: 'crypto-lobby' */ "./cryptoLobby"),
    },
    {
      path: "/loyalty",
      name: "loyalty",
      index: true,
      changefreq: "weekly",
      priority: 0.8,
      breadcrumb: "Loyalty",
      load: () => import(/* webpackChunkName: 'loyalty' */ "./loyalty"),
    },
    // {
    //   path: "/bonus",
    //   name: "bonus",
    //   load: () => import(/* webpackChunkName: 'bonus' */ "./bonus")
    // },
    // {
    //  path: '/:path',
    //  name: 'dashboard',
    //  load: () => import(/* webpackChunkName: 'dashboard' */ './dashboard'),
    // },
    // {
    //   path: "/:path/:instance",
    //   name: "bonusTermsAndConditionsCustom",
    //   load: () =>
    //     import(
    //       /* webpackChunkName: 'bonus-terms-and-conditions-custom' */ "./bonusTermsAndConditionsCustom"
    //     )
    // },
    // {
    //   path: "/:path/:testgroup?",
    //   name: "landing",
    //   load: () => import(/* webpackChunkName: 'landing' */ "./landing")
    // },
    // {
    //   path: "(.*)",
    //   name: "infoCasino",
    //   load: () => import(/* webpackChunkName: 'seo-page' */ "./seo-page.route")
    // },
    {
      path: "(.*)",
      name: "notFound",
      load: () => import(/* webpackChunkName: 'notFound' */ "./notFound"),
    },
    // {
    //   path: "(.*)",
    //   name: "notFoundSync",
    //   action: <div />
    // }
  ],

  action({ next }) {
    // Execute each child route until one of them return the result
    const route = next();

    // Provide default values for title, description etc.
    route.title = `${route.title || ""}`;
    route.description = route.description || "";

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: "/error",
    action: require("./error").default,
  });
}

export default routes;
