import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import IntlProvider from "./IntlProvider";
import WebSocketProvider from "./WebSocket";
import { ModalProvider } from "../Modal/ModalContext";
import { WalletProvider } from "./WalletContext";
import { GameBrowserProvider } from "../GameBrowser/GameBrowserContext";
import { AuthProvider } from "./AuthContext";
import { RouterProvider } from "./RouterContext";
import { DeviceProvider } from "./DeviceContext";
import { CentrifugeProvider } from "./CentrifugeContext";

function Provide({
  store,
  children,
  client,
  cookies,
  isMobile,
  route,
  pathname,
  routename,
  queryParams,
  isAuthenticated,
}) {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <IntlProvider locale="en">
          <RouterProvider
            route={route}
            pathname={pathname}
            queryParams={queryParams}
            routename={routename}
          >
            <AuthProvider cookies={cookies} isAuthenticated={isAuthenticated}>
              <CentrifugeProvider isAuthenticated={isAuthenticated}>
                <WalletProvider>
                  <DeviceProvider isMobile={isMobile}>
                    <ModalProvider>

                        {/*<GameBrowserProvider defaultFilter="feature" isMobile={isMobile}>*/}
                        {children}

                      {/*</GameBrowserProvider>*/}
                    </ModalProvider>
                  </DeviceProvider>
                </WalletProvider>
              </CentrifugeProvider>
            </AuthProvider>
          </RouterProvider>
        </IntlProvider>
      </ApolloProvider>
    </Provider>
  );
}

Provide.propTypes = {
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
};

export default Provide;
