import fetch from "../core/fetch";

function createFetchKnowingCookie({ cookie }) {
  if (!process.env.BROWSER) {
    return (url, options = {}) => {
      const isLocalUrl = /^\/($|[^/])/.test(url);

      // pass cookie only for itself.
      // We can't know cookies for other sites BTW
      if (isLocalUrl && options.credentials === "include") {
        const headers = {
          ...options.headers,
          cookie
        };
        return fetch(url, { ...options, headers });
      }

      return fetch(url, options);
    };
  }

  return fetch;
}

export default function createHelpers(config) {
  const fetchKnowingCookie = createFetchKnowingCookie(config);
  //const graphqlRequest = createGraphqlRequest(config.apolloClient);

  return {
    //client: config.apolloClient,
    history: config.history,
    fetch: fetchKnowingCookie,
    // @deprecated('Use `client` instead')
    // apolloClient: config.apolloClient,
    // @deprecated('Use `client.query()` or `client.mutate()` instead')
    //graphqlRequest,
    AutobahnReact: config.AutobahnReact
  };
}
