import React, { createContext, useContext, useReducer } from "react";

// import { ModalRoot } from "./ModalRoot";

const ModalContext = createContext({
  component: () => <div>No modal component supplied</div>,
  modalProps: {},
  showModal: () => undefined,
  hideModal: () => undefined,
});

const { Provider, Consumer: ModalConsumer } = ModalContext;

const reducer = (state, { type, component, displayName, modalProps }) => {
  switch (type) {
    case "openModal":
      const showIndex = state.modals
        .map((x) => x.displayName)
        .indexOf(component?.displayName);

      const idAlreadyExists = state.modals.find(
        (modal) => modal.displayName === component?.displayName
      );
      console.log("idAlreadyExists", idAlreadyExists);

      console.log("showIndex", showIndex);
      if (showIndex > -1 && state.modals[showIndex]) {
        const modals = [...state.modals];
        modals[showIndex].isActive = true;
        modals[showIndex].modalProps = modalProps;
        return { ...state, modals };
      }
      return {
        ...state,
        modals: [
          ...state.modals,
          ...[
            {
              displayName: component.displayName,
              isActive: true,
              component,
              modalProps,
            },
          ],
        ],
      };

    case "hideModal":
      let hideIndex = state.modals
        ?.filter((x) => x.isActive)
        .map((x) => x.displayName)
        .indexOf(displayName);
      const mainIndex =
        state.modals?.filter((x) => x.isActive)?.length > 1
          ? state.modals?.length - 1
          : hideIndex;
      const modals = [...state.modals];
      if (mainIndex > -1 && state.modals[mainIndex]) {
        // .slice(0, index)
        // .concat(state.modals.slice(index + 1));
        modals[mainIndex].isActive = false;
      }
      return { ...state, modals };
    default:
      throw new Error("Unspecified reducer action");
  }
};

const ModalProvider = ({ children }) => {
  const initialState = {
    modals: [],
    showModal: (component, modalProps = {}) => {
      dispatch({ type: "openModal", component, modalProps });
    },
    hideModal: (displayName) => {
      dispatch({ type: "hideModal", displayName });
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  // const Component = state.component;
  return (
    <Provider value={state}>
      {state.modals.map((modal) => {
        const Component = modal.component;
        return (
          Component && (
            <Component
              {...modal.modalProps}
              hideModal={() => state.hideModal(modal.component.displayName)}
              showModal={state.showModal}
              isActive={modal.isActive}
              key={modal.displayName}
            />
          )
        );
      })}

      {children}
    </Provider>
  );
};

const useModal = () => useContext(ModalContext);

export { ModalContext, ModalConsumer, ModalProvider, useModal };
