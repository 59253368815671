// @flow

import {defaultDataIdFromObject, InMemoryCache} from "@apollo/client";

function dataIdFromObject(obj) {
  switch (obj.__typename) {
    case "Promotion":
      return obj.link ? `Promotion:${obj.link}` : defaultDataIdFromObject(obj);
    default:
      return defaultDataIdFromObject(obj);
  }
}

export default function createCache() {
  // https://www.apollographql.com/docs/react/basics/caching.html#configuration
  return new InMemoryCache({
    dataIdFromObject,
    addTypename: true,
    cacheRedirects: {
      Query: {
        Promotion: (_, args, { getCacheKey }) =>
          getCacheKey({ __typename: "Promotion", slug: args.slug })
      }
    },
    typePolicies: {
      Query: {
        fields: {
          Promotion(_, { args, toReference }) {
            return toReference({
              __typename: "Promotion",
              slug: args.slug
            });
          }
        }
      },
      Promotion: {
        // In most inventory management systems, a single UPC code uniquely
        // identifies any product.
        keyFields: ["slug"]
      }
    }

    // typePolicies: {
    //   Promotion: {
    //     // In most inventory management systems, a single UPC code uniquely
    //     // identifies any product.
    //     keyFields: ["slug"]
    //   }
    // }
  });
}
