// @flow

/* eslint-disable import/prefer-default-export */
// import createApolloClient from '../../core/createApolloClient';
// import validateUsernameQuery from './validate-username.graphql';
// import validateEmailQuery from './validate-email.graphql';
// import checkUserExistsQuery from './check-user-exists.graphql';
// import loginUserQuery from './login-user.graphql';
// import getUserDetailsQuery from './get-user-details.graphql';
// import addRoleQuery from './add-role.graphql';
// import resetPasswordQuery from './reset-password.graphql';
// import requestNewPasswordQuery from './request-new-password.graphql';

import AutobahnReact from "../../core/websocket";
import { localeUrl } from "../../core/url";
import {
  postCall,
  getCall,
  patchCall,
  putCall,
  deleteCall,
} from "../../core/softswiss";
import { getSessions, closeSession } from "./session";

export const stats = () => getCall(`/api/player/stats`);

export const settings = () => getCall(`/api/player/settings`);

export const logUserIn = (usernameOrEmail, password) =>
  postCall("/api/users/sign_in", {
    user: {
      email: usernameOrEmail,
      password,
    },
  });

export const getPlayerInfo = () => getCall(`/api/player`);

export const confirm = (confirmation_token) =>
  getCall(`/api/users/confirmation?confirmation_token=${confirmation_token}`);

export const player_fields = (type) => getCall(`/api/info/player_fields`);

export const status_groups = () => getCall(`/api/player/status_groups`);

export const updateBonusPreference = (defaultBonus) =>
  patchCall(`/api/player/update_bonus_settings`, { can_issue: defaultBonus });

export const updateProfile = (context, fields) =>
  patchCall("/api/player", {
    context: context,
    player: {
      first_name: fields.firstname,
      last_name: fields.surname,
      date_of_birth: fields.birthDate,
      gender: fields.gender,
      country: fields.country?.name,
      address: fields.address1,
      city: fields.city,
      mobile_phone: fields.mobile_phone,
      postal_code: fields.postalCode,
      receive_promos:
        fields.receive_promos !== undefined
          ? fields.receive_promos
          : fields.marketing,
      receive_sms_promos:
        fields.receive_sms_promos !== undefined
          ? fields.receive_sms_promos
          : fields.marketing,
    },
  });

export const logOut = (locale, setAuthenticationStatus) => {
  deleteCall("/api/users/sign_out").then(() => {
    setAuthenticationStatus(false);
    return (window.location.href = `${localeUrl("home", locale)}`);
  });
};

export const setNewEmail = (email, password, captchaResponse) =>
  AutobahnReact("everymatrix").call(
    "/user/email#sendVerificationEmailToNewMailbox",
    {
      email,
      password,
      emailVerificationURL: `https://${window.location.hostname}/konto/fornya-e-post/$ORGINAL_EMAIL$/`,
      captchaPublicKey: CAPTCHA_PUBLIC_KEY,
      captchaResponse,
    }
  );

export const verifyNewEmail = (email, key) =>
  AutobahnReact("everymatrix").call("/user/email#verifyNewEmail", {
    email,
    key,
  });

export const setNewPassword = (
  current_password,
  password,
  password_confirmation
) =>
  putCall("/api/users", {
    user: { current_password, password, password_confirmation },
  });

export const getConsentRequirements = (action) =>
  AutobahnReact("everymatrix").call("/user/account#getConsentRequirements", {
    action,
  });

export const setUserConsents = (userConsents) =>
  AutobahnReact("everymatrix").call("/user#setUserConsents", {
    userConsents,
  });

export const isPersonalIDRegistered = (personalID) =>
  AutobahnReact("everymatrix").call("/user/account#isPersonalIDRegistered", {
    personalID,
  });

export const validateEmail = (email) =>
  AutobahnReact("everymatrix").call("/user/account#validateEmail", {
    email,
  });

export const validateUsername = (username) =>
  AutobahnReact("everymatrix").call("/user/account#validateUsername", {
    username,
  });

export const getIntendedVolumes = () =>
  AutobahnReact("everymatrix").call("/user/account#getIntendedVolumes");

export const validateCpr = (Cpr) =>
  AutobahnReact("everymatrix").call("/user/externalAuth#validateCpr", { Cpr });

export const getRofusStatus = (Cpr) =>
  AutobahnReact("everymatrix").call("/user/externalAuth#getRofusStatus", {
    Cpr,
  });

// export const register = () => Promise.resolve();

export const register = (registerParams) =>
  AutobahnReact("everymatrix").call("/user/account#register", {
    ...registerParams,
  });

export const getExternalLoginForm = (action, authParty, cpr = null) =>
  AutobahnReact("everymatrix").call("/user/externalAuth#getRedirectionForm", {
    action,
    authParty,
    cpr,
  });

export const subscribeExternalLoginForm = (callback) =>
  AutobahnReact("everymatrix").subscribe(
    "/externalAuth/statusChanged",
    (result) => callback(result.argsDict)
  );

export const unsubscribeExternalLoginForm = () =>
  AutobahnReact("everymatrix").unsubscribe("/externalAuth/statusChanged");

export const requestPasswordReset = (email: string, captchaResponse: string) =>
  postCall(`/api/users/password`, { user: { email } });

export const isResetPwdKeyAvailable = (key: string) =>
  AutobahnReact("everymatrix").call("/user/pwd#isResetPwdKeyAvailable", {
    key,
  });

export const resetPwd = (password, reset_password_token) =>
  putCall(`/api/users/password`, {
    user: { password, password_confirmation: password, reset_password_token },
  });

export const acceptTCv2 = (acceptedTermsType) =>
  AutobahnReact("everymatrix").call("/user#acceptTCv2", {
    acceptedTermsType,
  });

export const addLoginEntry = (referrerID, password) =>
  AutobahnReact("everymatrix").call("/user/externalAuth#addLoginEntry", {
    referrerID,
    password,
  });
