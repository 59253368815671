import React, { Children } from 'react';
import PropTypes from 'prop-types';
import deepForceUpdate from 'react-deep-force-update';
import Provider from './Provide';

// import ReactWastageMonitor from 'react-wastage-monitor'

// if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
//   ReactWastageMonitor(React, ReactDOM,{exclude: [
//     /Connect\([^\)]*\)/,
//     /WithStyles\([^\)]*\)/,
//     /InjectIntl\([^\)]*\)/,
//   ]})
// }

const ContextType = {
  // Enables critical path CSS rendering
  // https://github.com/kriasoft/isomorphic-style-loader
  insertCss: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  isMobile: PropTypes.any.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  cookies: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  pathname: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  routename: PropTypes.string.isRequired,
  queryParams: PropTypes.object.isRequired,
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  store: PropTypes.shape({
    subscribe: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 * The top-level React component setting context (global) variables
 * that can be accessed from all the child components.
 *
 * https://facebook.github.io/react/docs/context.html
 *
 * Usage example:
 *
 *   const context = {
 *     history: createBrowserHistory(),
 *     store: createStore(),
 *   };
 *
 *   ReactDOM.render(
 *     <App context={context}>
 *       <Layout>
 *         <LandingPage />
 *       </Layout>
 *     </App>,
 *     container,
 *   );
 */
class App extends React.PureComponent {
  static childContextTypes = ContextType;

  getChildContext() {
    return this.props.context;
  }

  componentDidMount() {
    const store = this.props.context && this.props.context.store;
    if (store) {
      this.unsubscribe = store.subscribe(() => {
        const state = store.getState();
        const newIntl = state.intl;
        if (this.intl !== newIntl) {
          this.intl = newIntl;

          if (__DEV__) {
            // eslint-disable-next-line no-console
            console.log('Intl changed — Force rendering');
          }
          deepForceUpdate(this);
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe();
      this.unsubscribe = null;
    }
  }

  render() {
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    const store = this.props.context?.store;
    const state = store?.getState();
    this.intl = state?.intl || {};
    const { initialNow, locale, messages } = this.intl;
    const localeMessages = (messages && messages[locale]) || {};
    const {
      client,
      cookies,
      isMobile,
      route,
      pathname,
      queryParams,
      routename,
      isAuthenticated,
    } = this.props.context;

    return (
      <Provider
        initialNow={initialNow}
        locale={locale}
        messages={localeMessages}
        defaultLocale="en"
        store={store}
        isMobile={isMobile}
        route={route}
        client={client}
        cookies={cookies}
        pathname={pathname}
        routename={routename}
        queryParams={queryParams}
        isAuthenticated={isAuthenticated}
      >
        {Children.only(this.props.children)}
      </Provider>
    );
  }
}

export default App;
