import AutobahnReact from '../../core/websocket';
import { getCall, postCall } from '../../core/softswiss';

export const watchBalance = () => AutobahnReact('everymatrix').call('/user/account#watchBalance', {
  expectBalance: true,
  expectBonus: true,
});

export const subscribeWalletChanges = (callback) => AutobahnReact('everymatrix').subscribe('/account/balanceChanged', (result) => callback(result.argsDict));
export const postGamingAccounts = (currency) => postCall(`/api/player/accounts?compatibility=false&currency=${currency}`)
export const getGamingAccounts = () => getCall('/api/player/accounts?compatibility=false');
export const getCurrencies = () => getCall('/api/info/currencies');
