export const TRACK_EVENTS = {
  PAGE: "Page",
  CLICK: "Click",
  ACTION: "Action",
  CLIENTERROR: "ClientError"
};

export const TRACK_CLICK_ACTIONS = {
  REGISTER: "Register",
  Login: "Click"
};

export const ANALYTICS_REGISTER_URL =
  "https://secure.adnxs.com/px?id=1653551&t=1";
export const ANALYTICS_FIRST_DEPOSIT_URL =
"https://secure.adnxs.com/px?id=1653550&seg=33506190&t=1";
export const ANALYTICS_DEPOSIT_RETURN_URL =
  "https://secure.adnxs.com/px?id=1653544&t=1";


//   <!-- Conversion Pixel - Return Deposit - DO NOT MODIFY -->
// <script src="https://secure.adnxs.com/px?id=1653544&t=1" type="text/javascript"></script>
// <!-- End of Conversion Pixel -->
// <!-- Conversion Pixel - First Deposit - DO NOT MODIFY -->
// <script src="https://secure.adnxs.com/px?id=1653550&t=1" type="text/javascript"></script>
// <!-- End of Conversion Pixel -->
// <!-- Conversion Pixel - Registration Success - DO NOT MODIFY -->
// <script src="https://secure.adnxs.com/px?id=1653551&t=1" type="text/javascript"></script>
// <!-- End of Conversion Pixel -->

// <!-- Conversion Pixel - Return Deposit - DO NOT MODIFY -->
// <img src="https://secure.adnxs.com/px?id=1653544&t=2" width="1" height="1" />
// <!-- End of Conversion Pixel -->
// <!-- Conversion Pixel - First Deposit - DO NOT MODIFY -->
// <img src="https://secure.adnxs.com/px?id=1653550&t=2" width="1" height="1" />
// <!-- End of Conversion Pixel -->
// <!-- Conversion Pixel - Registration Success - DO NOT MODIFY -->
// <img src="https://secure.adnxs.com/px?id=1653551&t=2" width="1" height="1" />
// <!-- End of Conversion Pixel -->

