import React, {createContext, useContext} from "react";

const DeviceContext = createContext({
  isMobile: undefined
});

const { Provider, Consumer: DeviceConsumer } = DeviceContext;

const DeviceProvider = ({ children, isMobile }) => {
  return <Provider value={{ isMobile }}>{children}</Provider>;
};

const useDevice = () => useContext(DeviceContext);

export { DeviceConsumer, DeviceProvider, useDevice };
