export function app(
  state = { isLoading: true, isShowCheckMark: false },
  action
) {
  switch (action.type) {
    case "APP_INITIALIZE_IN_PROGRESS":
      return {
        ...state,
        ...action.payload,
      };
    case "TOGGLE_CHECK_MARK": //green disc on my clubhouse page
      return {
        ...state,
        isShowCheckMark:
          action.isShowCheckMark !== undefined
            ? action.isShowCheckMark
            : !state?.isShowCheckMark,
      };
    case "APP_INITIALIZE_DONE":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
