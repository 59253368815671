import _ from "lodash";
import { createIntl, createIntlCache } from "react-intl";
import Cookies from "universal-cookie";
import { convertCurrency } from "../core/utils";
import { registerAffiliate } from "../data/model/affiliate";

export async function handleStag(stag) {
  const cookies = new Cookies();
  console.log(stag, "stagstagstagstag");
  registerAffiliate(stag);
  const expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + 2 * 60 * 60 * 1000);
  cookies.set("stagConfig", "1", { expires: expiryDate });
  return stag;
}

export function initStag(paramStag, defaultStag) {
  return (dispatch, getState, { AutobahnReact }) => {
    console.log("getState().user", getState().user);

    const stag = _.defaultTo(paramStag, _.defaultTo(defaultStag, ""));
    let affiliateId;
    if (stag !== "") {
      [affiliateId] = _.split(stag, "_");
    }

    dispatch({
      type: "SET_BTAG",
      payload: {
        stag,
        id: affiliateId,
      },
    });

    // let sessionStag = window.sessionStorage.getItem("stag",stag);

    // if incoming stag is null, check the one from the session
    // let validStag = _.defaultTo(stag, sessionStag);
    // window.sessionStorage.setItem("stag",validStag);
  };
}

// @todo, refactor this a bit
export function fetchAffiliateData(stag) {
  return async (dispatch, getState, { AutobahnReact, intl }) => {
    const licenseType = getState().license.type;

    return new Promise((resolve, reject) => {
      const cache = createIntlCache();
      const intl = createIntl(
        { locale: getState().intl.locale, messages: getState().intl.messages },
        cache
      );

      function getFreeRoundGames(arrayList) {
        const isAlt =
          getState().user.ipCountry == "CA" ||
          getState().user.ipCountry == "BE";

        let gamesBonus1Array = [];
        _.map(_.filter(arrayList, { type: "freeRound" }), (freeround, key) => {
          gamesBonus1Array = _.merge(
            gamesBonus1Array,
            isAlt ? freeround.altgames : freeround.games
          );
        });
        const lastGame = gamesBonus1Array.pop();
        return _.size(gamesBonus1Array) > 0
          ? `${_.join(gamesBonus1Array, ",")} or ${lastGame}`
          : lastGame;
      }

      function getPresentationData(rawData) {
        return {
          totalBonus: intl.formatNumber(
            convertCurrency(rawData.totalBonus, getState().wallet.baseCurrency),
            {
              style: "currency",
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            }
          ),
          totalFreespins: rawData.totalFreespins,
          percentageBonus1:
            _.sumBy(
              _.filter(rawData.bonuses.bonus_1, { type: "standard" }),
              "percentage"
            ) * 100,
          uptoBonus1: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_1, { type: "standard" }),
                "upto"
              ),
              getState().wallet.baseCurrency
            ),
            {
              style: "currency",
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            }
          ),
          freespinsBonus1: _.sumBy(
            _.filter(rawData.bonuses.bonus_1, { type: "freeRound" }),
            "rounds"
          ),
          gamesBonus1: getFreeRoundGames(rawData.bonuses.bonus_1),
          exampleBonus1: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_1, { type: "standard" }),
                "upto"
              ) /
                _.sumBy(
                  _.filter(rawData.bonuses.bonus_1, { type: "standard" }),
                  "percentage"
                ),
              getState().wallet.baseCurrency
            ),
            {
              style: "currency",
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            }
          ),
          exampleResultBonus1: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_1, { type: "standard" }),
                "upto"
              ) +
                _.sumBy(
                  _.filter(rawData.bonuses.bonus_1, { type: "standard" }),
                  "upto"
                ) /
                  _.sumBy(
                    _.filter(rawData.bonuses.bonus_1, { type: "standard" }),
                    "percentage"
                  ),
              getState().wallet.baseCurrency
            ),
            {
              style: "currency",
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            }
          ),

          percentageBonus2:
            _.sumBy(
              _.filter(rawData.bonuses.bonus_2, { type: "standard" }),
              "percentage"
            ) * 100,
          uptoBonus2: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_2, { type: "standard" }),
                "upto"
              ),
              getState().wallet.baseCurrency
            ),
            {
              style: "currency",
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            }
          ),
          gamesBonus2: getFreeRoundGames(rawData.bonuses.bonus_2),
          freespinsBonus2: _.sumBy(
            _.filter(rawData.bonuses.bonus_2, { type: "freeRound" }),
            "rounds"
          ),
          exampleBonus2: intl.formatNumber(
            convertCurrency(
              _.sumBy(
                _.filter(rawData.bonuses.bonus_2, { type: "standard" }),
                "upto"
              ) /
                _.sumBy(
                  _.filter(rawData.bonuses.bonus_2, { type: "standard" }),
                  "percentage"
                ),
              getState().wallet.baseCurrency
            ),
            {
              style: "currency",
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            }
          ),
          exampleResultBonus2: intl.formatNumber(
            convertCurrency(
              parseInt(
                _.sumBy(
                  _.filter(rawData.bonuses.bonus_2, { type: "standard" }),
                  "upto"
                )
              ) +
                parseInt(
                  _.sumBy(
                    _.filter(rawData.bonuses.bonus_2, { type: "standard" }),
                    "upto"
                  ) /
                    _.sumBy(
                      _.filter(rawData.bonuses.bonus_2, { type: "standard" }),
                      "percentage"
                    )
                ),
              getState().wallet.baseCurrency
            ),
            {
              style: "currency",
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            }
          ),

          percentageBonus3:
            _.sumBy(
              _.filter(rawData.bonuses.bonus_3, { type: "standard" }),
              "percentage"
            ) * 100,
          freespinsBonus3: _.sumBy(
            _.filter(rawData.bonuses.bonus_3, { type: "freeRound" }),
            "rounds"
          ),
          gamesBonus3: getFreeRoundGames(rawData.bonuses.bonus_3),

          minDeposit: intl.formatNumber(
            convertCurrency(20, getState().wallet.baseCurrency),
            {
              style: "currency",
              currency: getState().wallet.baseCurrency,
              minimumFractionDigits: 0,
            }
          ),
          amountDeposits: _.size(rawData.bonuses),
          bonusWagering: 35,
          freespinsWagering: 35,
        };
      }

      const rawData = {
        uk: {
          totalBonus: 100,
          totalFreespins: 50,
          bonuses: {
            bonus_1: [
              {
                type: "standard",
                upto: 100,
                percentage: 1,
                wr: 35,
              },
              {
                type: "freeRound",
                rounds: 50,
                wr: 40,
                games: ["Starburst"],
                altgames: ["Book of Oz"],
              },
            ],
          },
        },
        mga: {
          totalBonus: 300,
          totalFreespins: 200,
          bonuses: {
            bonus_1: [
              {
                type: "standard",
                upto: 100,
                percentage: 1,
                wr: 35,
              },
              {
                type: "freeRound",
                rounds: 50,
                wr: 40,
                games: ["Starburst"],
                altgames: ["Book of Oz"],
              },
            ],
            bonus_2: [
              {
                type: "standard",
                upto: "200",
                percentage: 0.5,
                wr: 35,
              },
              {
                type: "freeRound",
                rounds: 50,
                wr: 40,
                games: ["Starburst"],
                altgames: ["Book of Oz"],
              },
            ],
            bonus_3: [
              {
                type: "freeRound",
                rounds: 100,
                upto: "100",
                percentage: 1,
                games: ["Starburst"],
                altgames: ["Book of Oz"],
              },
            ],
          },
        },
      };
      const currency = getState().wallet.baseCurrency;
      if (getState().websocket.isMadmanConnected) {
        AutobahnReact("madman")
          .call("com.cms.affiliate.getOffers", {
            stag: getState().affiliate.stag,
          })
          .then((result) => {
            if (_.has(result, "offers")) {
              try {
                var rawData = JSON.parse(
                  licenseType == "uk"
                    ? result.offers.configuk
                    : result.offers.configmga
                );
              } catch (e) {
                console.log("Old format detected.");
              }
              dispatch({
                type: "AFFILIATE_DATA",
                payload: {
                  welcomePackage: {
                    presentation: getPresentationData(rawData),
                    rawData,
                  },
                },
              });
            } else {
              var rawData = {
                uk: {
                  totalBonus: 100,
                  totalFreespins: 50,
                  bonuses: {
                    bonus_1: [
                      {
                        type: "standard",
                        upto: 100,
                        percentage: 1,
                        wr: 35,
                      },
                      {
                        type: "freeRound",
                        rounds: 50,
                        wr: 40,
                        games: ["Starburst"],
                        altgames: ["Book of Oz"],
                      },
                    ],
                  },
                },
                mga: {
                  totalBonus: 300,
                  totalFreespins: 200,
                  bonuses: {
                    bonus_1: [
                      {
                        type: "standard",
                        upto: 100,
                        percentage: 1,
                        wr: 35,
                      },
                      {
                        type: "freeRound",
                        rounds: 50,
                        wr: 40,
                        games: ["Starburst"],
                        altgames: ["Book of Oz"],
                      },
                    ],
                    bonus_2: [
                      {
                        type: "standard",
                        upto: "200",
                        percentage: 0.5,
                        wr: 35,
                      },
                      {
                        type: "freeRound",
                        rounds: 50,
                        wr: 40,
                        games: ["Starburst"],
                        altgames: ["Book of Oz"],
                      },
                    ],
                    bonus_3: [
                      {
                        type: "freeRound",
                        rounds: 100,
                        upto: "100",
                        percentage: 1,
                        games: ["Starburst"],
                        altgames: ["Book of Oz"],
                      },
                    ],
                  },
                },
              };

              dispatch({
                type: "AFFILIATE_DATA",
                payload: {
                  welcomePackage: {
                    presentation: getPresentationData(rawData[licenseType]),
                    rawData: rawData[licenseType],
                  },
                },
              });
            }
            resolve();
          });
      } else {
        dispatch({
          type: "AFFILIATE_DATA",
          payload: {
            welcomePackage: {
              presentation: getPresentationData(rawData[licenseType]),
              rawData: rawData[licenseType],
            },
          },
        });
        resolve();
      }
    });
  };
}
