import React, {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import useSWR from "swr";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import {
  getGamingAccounts,
  getCurrencies,
  subscribeWalletChanges,
  watchBalance,
  postGamingAccounts,
} from "../../data/model/wallet";

import { useAuth } from "./AuthContext";
import { getGrantedBonuses } from "../../data/model/bonus";
import { useRouter } from "./RouterContext";
import { getIpInfo } from "../../data/model/session";
import getCurrenciesQuery from "../LandingOffer/get-currencies.graphql";
import getCountriesQuery from "../LandingOffer/get-countries.graphql";
import { useCentrifuge } from "../Provide/CentrifugeContext";

import { getPlayerDetails } from "../../data/model/profile";
import { postCall } from "../../core/softswiss";

const WalletContext = createContext({
  currency: undefined,
  total: undefined,
  gamingAccountID: undefined,
  loading: true,
  totalCasinoWallet: 0,
  totalBonusWallet: 0,
  gamingAccountId: 0,
  lockedAmount: 0,
  fractionDigits: 2,
});

const { Provider, Consumer: WalletConsumer } = WalletContext;

const reducer = (state, { type, wallet }) => {
  switch (type) {
    case "walletUpdate":
      return { ...state, ...wallet };
    default:
      throw new Error("Unspecified reducer action");
  }
};

const WalletProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { loading: true });
  const { isAuthenticated } = useAuth();
  const { subscribe } = useCentrifuge();
  const intl = useIntl();

  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  const {
    loading: currencyLoading,
    error: currencyError,
    previousData: previousCurrencies,
    data: currencies,
  } = useQuery(getCurrenciesQuery);
  const {
    loading: countryLoading,
    error: countryError,
    previousData: previousCountries,
    data: countries,
  } = useQuery(getCountriesQuery);
  const [lang, country] = intl.locale.split("-");

  const searchCountry = ["row", "eu", "us", "ar", "ee"].includes(country)
    ? ipInfo?.country_code
    : country?.toUpperCase();

  const countryInfo = countries?.countries?.find(
    (item) => item?.code === searchCountry
  );
  const currencyInfo = currencies?.currencies?.find(
    (item) => item?.code === countryInfo?.currency
  );
  const localeInfo = {
    ...countryInfo,
    ...currencyInfo,
    currency: currencyInfo?.code,
  };

  const { data: playerDetails, mutate: reFetchPlayerDetail } = useSWR(
    isAuthenticated ? "/api/player" : null,
    getPlayerDetails
  );

  const { data, mutate: reFetchGamingAccounts } = useSWR(
    isAuthenticated ? "/api/player/accounts" : null,
    getGamingAccounts
    // { refreshInterval: 5000 },
  );

  useEffect(() => {
    if (isAuthenticated) {
      // const privateChannels = ["balance","$stats","game_limits", "comps_award", "bonuses_changes", "freespins_changes", "payments_changes"];
      console.log("balance#player_id subscribe");
      // subscribe("balance", (message) => {
      //   console.log("balance#player_id", message);
      // });
      subscribe("$analytics", (message) => {
        console.log("$analytics", message);
        if (
          message?.data?.category === "game" &&
          message?.data?.action === "finished"
        ) {
          console.log("balance#player_id REFETCH");
          reFetchGamingAccounts();
        }
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      reFetchPlayerDetail();
      reFetchGamingAccounts();
    }
  }, [isAuthenticated]);

  const { data: totalcurrencies } = useSWR("getCurrencies", getCurrencies);
  const currentWallet = totalcurrencies?.filter(
    (cur) =>
      cur?.code ===
      (playerDetails?.currency ??
        (["row", "eu", "us"].includes(country)
          ? country === "eu"
            ? "EUR"
            : "USD"
          : localeInfo.currency))
  );
  const { data: bonuses } = useSWR(
    isAuthenticated ? "getGrantedBonuses" : null,
    getGrantedBonuses
  );
  const bonusList = bonuses?.filter?.((item) =>
    ["handle_bets", "wait", "issued"].includes(item.stage)
  );
  const account = data?.find(
    (item) => item.currency === playerDetails?.currency
  );
  // const totalBonus = bonusList?.length > 0 ? (bonusList?.reduce((a, b) => a + b?.amount_cents, 0) / 100) : 0;
  const total = account?.amount_cents
    ? currentWallet &&
      account?.amount_cents / currentWallet[0]?.subunits_to_unit
    : 0;
  const available_to_cashout_cents = account?.available_to_cashout_cents
    ? currentWallet &&
      account?.available_to_cashout_cents / currentWallet[0]?.subunits_to_unit
    : 0;
  const activeCurrency =
    playerDetails?.currency ??
    (["row", "eu", "us"].includes(country)
      ? country === "eu"
        ? "EUR"
        : "USD"
      : localeInfo.currency);

  useEffect(() => {
    dispatch({ type: "walletUpdate", wallet });
  }, [playerDetails, total, data]);

  const wallet = {
    currency: activeCurrency,
    total: intl.formatNumber(total, {
      style: "currency",
      currency: activeCurrency,
      minimumFractionDigits:
        currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      maximumFractionDigits:
        currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    }),
    gamingAccountID: 1,
    loading: isAuthenticated ? !data : false,
    totalCasinoWallet: intl.formatNumber(total, {
      style: "currency",
      currency: activeCurrency,
      minimumFractionDigits:
        currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      maximumFractionDigits:
        currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    }),
    totalWithdraw: intl.formatNumber(available_to_cashout_cents, {
      style: "currency",
      currency: activeCurrency,
      minimumFractionDigits:
        currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      maximumFractionDigits:
        currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    }),
    totalBonusWallet: total - available_to_cashout_cents,
    lockedAmount: intl.formatNumber(total - available_to_cashout_cents, {
      style: "currency",
      currency: activeCurrency,
      minimumFractionDigits:
        currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
      maximumFractionDigits:
        currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    }),
    fractionDigits:
      currentWallet?.[0]?.subunits_to_unit?.toString()?.length - 1 ?? 2,
    subUnitsToUnit: currentWallet?.[0]?.subunits_to_unit ?? 100,
  };

  React.useMemo(() => {
    dispatch({ type: "walletUpdate", wallet });
  }, [
    data,
    isAuthenticated,
    playerDetails,
    account?.amount_cents,
    bonusList?.length,
    currencies,
    countries,
    totalcurrencies,
  ]);

  return <Provider value={state}>{children}</Provider>;
};

const useWallet = () => useContext(WalletContext);

export { WalletConsumer, WalletProvider, useWallet };
