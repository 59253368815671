import React, { createContext, useContext, useReducer } from "react";
import { openNavigation } from "@actions/navigation";
import useSession from "@hooks/useSession";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useTracking } from "@hooks/useTracking";

const AuthContext = createContext({
  currency: undefined,
  total: undefined,
  gamingAccountID: undefined,
  isAuthenticated: false,
  isAuthLoading: false,
});

const { Provider, Consumer: AuthConsumer } = AuthContext;

const reducer = (state, { type, Session, isAuthenticated, isAuthLoading, cmsSessionID }) => {
  switch (type) {
    case "setCmsSessionID":
      return { ...state, cmsSessionID };
    case "setAuthenticationStatus":
      return { ...state, isAuthenticated };
    case "setAuthIsLoading":
      return { ...state, isAuthLoading };
    default:
      throw new Error("Unspecified reducer action");
  }
};

const AuthProvider = ({ children, cookies }) => {
  const { session, isLoading } = useSession();
  const reduxDispatch = useDispatch();
  const { identify } = useTracking();
  let isAuthenticated;
  const intl = useIntl();

  // const { loading, error, argsDict } = useQuery(isActiveUserSessionQuery, {
  //   errorPolicy: 'all',
  //   variables: { cmsSessionID },
  //   skip: !cmsSessionID,
  //   fetchPolicy: "no-cache",
  //   ssr: false
  // });
  const initialState = {
    isAuthenticated: cookies.get("isAuthenticated") === "true",
    isAuthLoading: true,
    // isAuthenticated: false,
    setCmsSessionID: (cmsSessionID) => {
      cookies.set("cmsSessionID", cmsSessionID);
      dispatch({ type: "setCmsSessionID", cmsSessionID });
    },
    setAuthenticationStatus: (isAuthenticated) => {
      cookies.set("isAuthenticated", isAuthenticated, {
        path: "/",
        maxAge: 60 * 20,
      });
      dispatch({ type: "setAuthenticationStatus", isAuthenticated });
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  // if (process.env.BROWSER) {
  //   React.useEffect(() => {
  //     if(!isLoading)
  //       initialState.setAuthenticationStatus(session?.length > 0);

  //   }, [session?.length, isAuthenticated]);
  // }
  if (process.env.BROWSER) {
    React.useEffect(() => {
      if (!isLoading) {
        identify(session?.id, session);
        isAuthenticated = !!session?.id;
        dispatch({ type: "setAuthIsLoading", isAuthLoading: false })

        console.log("authsession", session);

        // if(!isAuthenticated){
        //   window.location.href = `${localeUrl('home', intl.locale)}`
        // }

        if (session?.auth_fields_missed?.includes?.("terms_acceptance")) {
          reduxDispatch(
            openNavigation("Auth", {
              initialAction: "acceptTermsAndConditions",
            })
          );
        } else {
          if (state.isAuthenticated !== isAuthenticated) {
            dispatch({
              type: "setAuthenticationStatus",
              isAuthenticated,
            });
          }
          cookies.set("isAuthenticated", isAuthenticated, {
            path: "/",
            maxAge: 60 * 20,
          });
        }
      }
    }, [isAuthenticated, isLoading]);
  }
  return <Provider value={state}>{children}</Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthConsumer, AuthProvider, useAuth };
