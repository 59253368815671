export default function navigation(
  state = { isNavigationOpen: false, isGameBrowserActive: false, isGameModalOpen: true },
  action,
) {
  switch (action.type) {
    case 'NAVIGATION_IS_OPEN':
    case 'NAVIGATION_IS_CLOSED':
      return {
        ...state,
        isNavigationOpen: action.isNavigationOpen,
        item: action.item,
        params: action.params,
      };

    case 'GAMEBROWSER_IS_ACTIVE':
      return {
        ...state,
        isGameBrowserActive: action.isGameBrowserActive,
      };

    case 'BOTTOM_MENU_STATUS':
      return {
        ...state,
        bottomMenuStatus: action.bottomMenuStatus,
      };
      case 'FORCEFULLY_TOGGLE_GAME_MODAL':
      return {
        ...state,
        isGameModalOpen: action.isGameModalOpen,
      };

    default:
      return state;
  }
}
