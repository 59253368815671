export function updateTag(tagName, keyName, keyValue, attrName, attrValue) {
  const node = document.head.querySelector(`${tagName}[${keyName}='${keyValue}']`);
  if (node && node.getAttribute(attrName) === attrValue) return;

  // Remove and create a new tag in order to make it work with bookmarks in Safari
  if (node) {
    node.parentNode.removeChild(node);
  }
  if (typeof attrValue === 'string') {
    const nextNode = document.createElement(tagName);
    nextNode.setAttribute(keyName, keyValue);
    nextNode.setAttribute(attrName, attrValue);
    document.head.appendChild(nextNode);
  }
}

export function updateMeta(name, content) {
  updateTag('meta', 'name', name, 'content', content);
}

export function updateCustomMeta(property, content) {
  updateTag('meta', 'property', property, 'content', content);
}

export function updateLink(rel, href) {
  updateTag('link', 'rel', rel, 'href', href);
}

export function updateAlternate(hreflang) {
  Array.from(document.head.querySelectorAll('link[rel=alternate]')).forEach((node) => {
    if (node) {
      node.parentNode.removeChild(node);
    }
  });

  if (typeof hreflang === 'object') {
    Object.keys(hreflang).forEach((key) => {
      console.log('typeof hreflang[key]', typeof hreflang[key]);
      if (typeof hreflang[key] === 'string') {
        const nextNode = document.createElement('link');
        nextNode.setAttribute('rel', 'alternate');
        nextNode.setAttribute('href', hreflang[key]);
        nextNode.setAttribute('hreflang', key);
        document.head.appendChild(nextNode);
      }
    });

    // Also set a default
    if (typeof hreflang.en === 'string') {
      const nextNode = document.createElement('link');
      nextNode.setAttribute('rel', 'alternate');
      nextNode.setAttribute('href', hreflang.en);
      nextNode.setAttribute('hreflang', 'x-default');
      document.head.appendChild(nextNode);
    }
  }
}
