import {applyMiddleware, compose, createStore} from "redux";
// import { createResponsiveStoreEnhancer } from "redux-responsive";
import {createTracker} from "redux-segment";
import thunk from "redux-thunk";
import createRootReducer from "../reducers";
import createHelpers from "./createHelpers";
import createLogger from "./logger";

export default function configureStore(initialState, config) {
  const helpers = createHelpers(config);
  const { isMobile } = config;
  const tracker = createTracker();
  const middleware = [
    thunk.withExtraArgument(helpers),
    tracker // only add tracker to client side
  ];

  let enhancer;

  if (__DEV__) {
    if (__SHOWLOGS__ && process.env.BROWSER) {
      middleware.push(createLogger());
    }

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    let devToolsExtension = f => f;
    /* eslint-disable no-underscore-dangle */
    if (process.env.BROWSER && window.__REDUX_DEVTOOLS_EXTENSION__) {
      devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
    }
    /* eslint-enable */

    enhancer = compose(
      // createResponsiveStoreEnhancer({ calculateInitialState: false }),
      applyMiddleware(...middleware),
      devToolsExtension
    );
  } else {
    enhancer = compose(
      // createResponsiveStoreEnhancer({ calculateInitialState: false }),
      applyMiddleware(...middleware)
    );
  }

  const rootReducer = createRootReducer({
    isMobile
  });

  // See https://github.com/rackt/redux/releases/tag/v3.1.0
  const store = createStore(rootReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept("../reducers", () =>
      // Don't forget to remove `()` if you change reducers back to normal rootReducer.
      // eslint-disable-next-line global-require
      store.replaceReducer(require("../reducers").default())
    );
  }

  return store;
}
