// it might be a project-level reusable hook
import React, { useEffect } from "react";
import useSWR from "swr";
import { getSessionInfo } from "@data/model/session";
import universalCookies from "universal-cookie";
import history from "../core/history";
import { localeUrl } from "../core/url";
import { useIntl } from "react-intl";
import { getIpInfo } from "../data/model/session";

const cookies = new universalCookies();

export const useSession = () => {
  const { data, error } = useSWR("getSessionInfo", getSessionInfo);
  const { data: ipInfo } = useSWR("getIpInfo", getIpInfo);
  console.log("ipInfo--ipInfo", ipInfo);

  useEffect(() => {
    getSessionInfo().then((session) => {
      console.log("check session", session);
      cookies.set("isAuthenticated", !!session?.id, {
        path: "/",
        maxAge: 60 * 20,
      });
    });
  }, []);

  return {
    session: data,
    isLoading: !error && !data,
    isError: error,
    userCountry: data?.country ?? ipInfo?.country_code,
  };
};

export default useSession;
