import React, {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import { settings } from "../../data/model/account";
// import CentrifugeConnection from "centrifuge";
import { useAuth } from "./AuthContext";
// import { ModalRoot } from "./ModalRoot";

const CentrifugeContext = createContext({
  component: () => <div>No modal component supplied</div>,
  modalProps: {},
  showModal: () => undefined,
  hideModal: () => undefined,
});

const { Provider, Consumer: CentrifugeConsumer } = CentrifugeContext;

const reducer = (state, { type, topic, callback }) => {
  switch (type) {
    case "subscribe":
      return {
        ...state,
      };

    default:
      throw new Error("Unspecified reducer action");
  }
};

const CentrifugeProvider = ({ children }) => {

  const [connection, setConnection] = useState(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const { isAuthenticated } = useAuth();
  console.log('CentrifugeProvider', isAuthenticated)
  let conn = null;

  const privateChannels = [
    "$balance",
    "$stats",
    "game_limits",
    "comps_award",
    "bonuses_changes",
    "freespins_changes",
    "payments_changes",
  ];

  // useEffect(() => {
  //   const getPlayerSettings = async () => {
  //     let playerSettings = await settings();

  //     console.log('playerSettings', playerSettings)
  //     //if (playerSettings?.cent && !isConnecting && !connection) {
  //       setIsConnecting(true);
  //       playerSettings.cent.authEndpoint = `https://backend.theclubhousecasino.com${playerSettings?.cent?.authEndpoint}`;
  //       conn = new Centrifuge(playerSettings?.cent);
  //       const privateChannels = ["$balance","$stats","game_limits", "comps_award", "bonuses_changes", "freespins_changes", "payments_changes"];
  //       // privateChannels.forEach((channel) => conn.subscribe(`${channel}#${playerSettings?.cent?.user}`, console.log));
  //       // conn.connect();
  //       setConnection(conn);
  //     //}
  //   };
  //   getPlayerSettings();
  // }, [isAuthenticated]);

  const handleMessages = async (topic, callback) => {
    let playerSettings = await settings();
    playerSettings.cent.authEndpoint = `https://backend.theclubhousecasino.com${playerSettings?.cent?.authEndpoint}`;
    const connection = new Centrifuge(playerSettings?.cent);
    console.log("playerSettings--", playerSettings);

    connection.subscribe(
      `${topic}#${playerSettings?.cent?.user}`,
      (message) => {
        console.log(`handleMessages--message--message *--*${topic}`, message);
        callback && callback(message);
      }
    );

    connection.connect();

    connection.on("connect", function (context) {
      console.log(`now client connected to Centrifugo and authorized *--*${topic}`, context);
      // now client connected to Centrifugo and authorized
    });

    connection.on("disconnect", function (context) {
      connection.connect();
      console.log("now client dis connected to Centrifugo", context);
      // do whatever you need in case of disconnect from server
    });

    connection.on("error", function (error) {
      console.log("handle error in a way you want", error);
      // handle error in a way you want, here we just log it into browser console.
    });
  };
  const subscribe = (topic, callback) => {
    // if (conn) {
    // conn.subscribe(topic, callback);
    handleMessages(topic, callback);
    // } else {
    //   console.log("window.setTimeout", conn);
    //   window.setTimeout(() => {
    //     subscribe(topic, callback);
    //   }, 1000);
    // }
  };

  const initialState = {
    subscribe,
  };

  // const Component = state.component;
  return <Provider value={initialState}>{children}</Provider>;
};

const useCentrifuge = () => useContext(CentrifugeContext);

export {
  CentrifugeContext,
  CentrifugeConsumer,
  CentrifugeProvider,
  useCentrifuge,
};
