export default function affiliate(state = { id: '' }, action) {
  switch (action.type) {
    case 'SET_BTAG':
      return {
        ...state,
        ...action.payload,
      };
    case 'AFFILIATE_DATA':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
