import configStage from "./config.stage";
import configProd from "./config.prod";

export const getConfig = (isProd) => (isProd ? configProd : configStage);

export const ssrCountries = {
  au: "5.62.23.26",
  ie: "81.17.242.166",
  ch: "84.17.52.145",
  nz: "103.76.164.30",
  ca: "92.99.89.197",
  lu: "92.38.172.15",
  de: "159.242.234.164",
  ae: "102.177.185.0",
  at: "94.198.41.133",
  kw: "5.62.62.193",
};

export const version = "1.0.0";
// export const cfImgUrl = 'https://theclubhousecasino.com/cdn-cgi/image';
export const cfImgUrl = false;
export const supportEmail = "support@theclubhousecasino.com";

export const askGdprConsent = false;
export const port = 8080;
export const host = `localhost:${port}`;

export const supportedLocales = [
  "x-default",
  "en-eu",
  "en-ca",
  "en-in",
  "en-ie",
  "de-de",
  "de-ch",
  "et-ee",
  "ar-ar",
  "en-nz",
  "en-au",
  "no-no",
];

export const locales = ["da-DK"];

export const cockpit = {
  url: "https://cockpit-ch.madmenmt.com",
  token: "account-b931fc4ac176c674c21a035eec0e26",
};

export const analytics = {
  segment: {
    writeKey: "FdG4CgBvNdRPT7hgNd0olLJZsgVfcSC4", // FdG4CgBvNdRPT7hgNd0olLJZsgVfcSC4
  },
};

export const consent = {
  necessary: ["LiveChat", "Amplitude", "SendinBlue"],
  analytics: ["Mixpanel", "Google Analytics"],
  marketing: ["QuantCast"],
};

export const cookieControlConfig = {
  token: "2b336d65e38b4241764bd26c6e70187b46f4d0c4", // 592b99ebdf88c091dad9b556b6d8de236ac97687
};

// time is in miliseconds
export const callTimers = {
  default: 10000, // 10 seconds default
};

export const livechat = {
  license: 3953241,
  locales: {
    default: 57,
    no: 58,
    sv: 59,
    fi: 60,
    de: 95,
  },
  blacklist: [
    "askgamblers",
    "complain",
    "authority",
    "ukgc",
    "mga",
    "exclusion",
    "exclude",
    "kyc",
  ],
};

export const captchaPublicKey = "6LfPIgYTAAAAACEcTfYjFMr8y3GX6qYVLoK-2dML";
export const cookiedomain = "playfrank.com";

const prodconfig = {
  // SITE_DOMAIN: "http://www.quickspinner.com",
  SITE_DOMAIN: "https://theclubhousecasino.com",
  API_ENDPOINT: {
    // WSURI: "wss://api.playfrank.com/v2",
    // WSURI: "wss://api-stage.playfrank.com/v2",
    WSURI: "wss://api-stage.everymatrix.com/v2",
    // WSURI: "wss://playfrank-api.everymatrix.com/v2",
    FALLBACK_URL: "https://playfrank-api.everymatrix.com/longpoll",
  },
  PLFAPI_ENDPOINT: {
    WSURI: "wss://crossbar.playfrank.com/ws",
    FALLBACK_URL: "https://crossbar.playfrank.com/lp",
  },
  EVO_ENDPOINT: {
    WSURI:
      "wss://evolutionlivecasino.gammatrix.com/api/lobby/v1/bmnjwb3t7j5dd7oy/live",
  },
};

const stageconfig = {
  // SITE_DOMAIN: "http://www.quickspinner.com",
  SITE_DOMAIN: "https://theclubhousecasino.com",
  API_ENDPOINT: {
    // WSURI: "wss://api.playfrank.com/v2",
    // WSURI: "wss://api-stage.playfrank.com/v2",
    WSURI: "wss://api-stage.everymatrix.com/v2",
    // WSURI: "wss://playfrank-api.everymatrix.com/v2",
    FALLBACK_URL: "https://playfrank-api.everymatrix.com/longpoll",
  },
  PLFAPI_ENDPOINT: {
    WSURI: "wss://crossbar.playfrank.com/ws",
    FALLBACK_URL: "https://crossbar.playfrank.com/lp",
  },
  EVO_ENDPOINT: {
    WSURI:
      "wss://evolutionlivecasino.gammatrix.com/api/lobby/v1/bmnjwb3t7j5dd7oy/live",
  },
};

export const robots = [
  "pingdom",
  "Googlebot",
  "oBot",
  "bingbot",
  "AhrefsBot",
  "python",
  "YandexBot",
  "SemrushBot",
  "Screaming Frog SEO Spider",
];

export const webApi = prodconfig;
