import UniversalRouter from 'universal-router';
import routes from './routes/index';

export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (typeof context.route.load === 'function') {
      return context.route.load().then((action) => action.default(
        {
          ...context,
          index: context.route.index,
          name: context.route.name,
        },
        params,
      ));
    }
    return undefined;
  },
});
