import React from 'react';
import { isBrowser } from 'browser-or-node';
export const useTracking = (initialMode = null) => {
  const track = (label, params) => {
    if(isBrowser) {
      window?.analytics?.track(`Action: ${label}`, params);
    }
  };

  const click = (label, params = {}) => {
    if(isBrowser) {
      window?.analytics?.track(`Click: ${label}`, params);
    }
  };

  const page = (label, params = {}) => {
    if(isBrowser) {
      window?.analytics?.track(`Page Access: ${label}`, params);
    }
  };

  const identify = (id, params = {}) => {
    if(isBrowser) {
      window?.analytics?.identify(id, params);
    }
  };
  return { track, click, page, identify };
};
