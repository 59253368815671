import React, { createContext, useContext, useEffect, useState } from "react";

const RouterContext = createContext({
  route: undefined,
  pathname: undefined,
  queryParams: undefined,
  routename: undefined,
});

const { Provider, Consumer: RouterConsumer } = RouterContext;

const RouterProvider = ({
  children,
  route,
  pathname,
  queryParams,
  routename,
}) => {
  const [breadcrumb, setBreadcrumb] = useState(route.breadcrumb);

  const [fakeRouteName, setfakeRouteName] = useState(routename);

  const [routes, setRoutes] = useState({
    currentRoute: { ...route, breadcrumb },
    previousRoute: null,
  });

  const setRoute = (newRoute) => {
    setfakeRouteName(newRoute);
  };

  useEffect(() => {
    setRoutes((prevState) => ({
      previousRoute: { ...prevState.currentRoute },
      currentRoute: { ...route },
    }));
  }, [route?.name]);

  useEffect(() => {
    setfakeRouteName(routename);
  }, [routename]);

  return (
    <Provider
      value={{
        pathname,
        queryParams,
        routename: fakeRouteName || routename,
        currentRoute: route,
        previousRoute: { ...routes.previousRoute },
        setBreadcrumb,
        setRoute,
      }}
    >
      {children}
    </Provider>
  );
};

const useRouter = () => useContext(RouterContext);

export { RouterConsumer, RouterProvider, useRouter };
