import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { app } from './app';

import intl from './intl';
import navigation from './navigation';

import { default as affiliate } from './affiliate';

export default function createRootReducer({ isMobile }) {
  return combineReducers({
    app,
    affiliate,
    navigation,

    // browser: createResponsiveStateReducer(
    //   {
    //     extraSmall: 480,
    //     small: 768,
    //     medium: 992,
    //     large: 1200
    //   },
    //   {
    //     initialMediaType: isMobile.any ? "small" : "infinity"
    //   }
    // ),
    // browser: createResponsiveStateReducer(null),

    form: reduxFormReducer,
    intl,
  });
}
