// polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import main from "./client";

// Run the application when both DOM is ready and page content is loaded
// if (
//   ['complete', 'loaded', 'interactive'].includes(document.readyState) &&
//   document.body
// ) {
// } else {
//   document.addEventListener('DOMContentLoaded', main, false);
// }
main();
//
// if (!global.Intl) {
//   // You can show loading banner here
//
//   require.ensure(
//     [
//       // Add all large polyfills here
//       'intl',
//       'intl/locale-data/jsonp/en.js',
//       'intl/locale-data/jsonp/de.js',
//       'intl/locale-data/jsonp/nn-NO.js',
//       'intl/locale-data/jsonp/fi.js',
//     ],
//     require => {
//       // and require them here
//       require('intl');
//       require('intl/locale-data/jsonp/en.js');
//       require('intl/locale-data/jsonp/de.js');
//       require('intl/locale-data/jsonp/nn-NO.js');
//       require('intl/locale-data/jsonp/fi.js');
//
//       run();
//     },
//     'polyfills',
//   );
// } else {
//   run();
// }
