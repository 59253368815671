import _ from "lodash";
// import Connection from "./Connection.js";
import {Wampy} from "@lib/wampy/src/wampy.js";
import {TRACK_EVENTS} from "@constants/Analytics";
// import { checkUserSession } from "../../actions/user";
import {trackAction} from "../../actions/analytics.js";
import {callTimers} from "../../config.js";


class Autobahn {
  constructor() {
    // this.Connection = new Connection();
    this.ws = {};
    this.exhaustCollection = [];
    this.culture = "en";
    this.quotaIsOpen = false;
    this.executeCall = this.executeCall.bind(this);
    this.callMemoize = _.memoize(this.executeCall, this.resolver);
    // this.Decorators = Decorators;
  }

  setDispatcher(dispatcher) {
    this.dispatch = dispatcher;
  }

  setCulture(culture) {
    this.culture = culture;
  }

  initialize(connectURL, options) {
    this.ws = new Wampy(`${connectURL}`, options);
  }

  isConnectionReady() {
    return (
      this.Connection.currentConnection &&
      this.Connection.currentConnection.isOpen
    );
  }

  publish() {
    return this.Connection.currentConnection.session.publish(...arguments);
  }

  async subscribe(method, handler, options = {}) {
    this.ws.subscribe(method, handler, options, {
      onSuccess() {
        console.log("Successfully subscribed to topic");
      },
      onError(errData) {
        console.log(`Subscription error:${errData.error}`);
      },
        onEvent: handler
    });
  }

  async unsubscribe(method, handler) {
    this.ws.unsubscribe(method, {
      onSuccess() {
        console.log("Successfully unssubscribed to topic");
      },
      onError(errData) {
        console.log(`Subscription error:${errData.error}`);
      },
      onEvent: handler
    });
  }

  resolver(method, resolve, reject, params, config) {
    return config.cacheKey;
  }

  async clearCache() {
    const values = this.callMemoize;
    values.cache.clear();
  }

  call(method, params = {}, options = { cache: false, cacheKey: "" }) {
    return new Promise((resolve, reject) => {
      this.ws.call(method, params, {
        onSuccess(result) {
          resolve(result.argsDict);
        },
        onError(err) {
          reject(err.argsDict);
        }
      });
    });
    /*
    }else{
      console.log('Connection not ready:',method)
      return this.Connection.reconnectAnonymously().then(function(){
        console.log('reconnect:',method)
        return this.executeCall(method, params);
      })
    }
    */
  }

  async executeCall(method, resolve, reject, params = {}) {
    const t0 = process.env.BROWSER ? performance.now() : Date.now();
    return this.Connection.currentConnection.then(
      (_this => connection => {
        if (!connection || !connection.session) {
          resolve({});
        }
        return connection.session
          .call(method, null, Object.assign(params, { culture: this.culture }))
          .then(
            result => {
              const t1 = process.env.BROWSER ? performance.now() : Date.now();
              const maxTime = _.get(callTimers, method, callTimers.default);

              if (t1 - t0 > maxTime) {
                this.dispatch(
                  trackAction("Maximum execution time violation", {
                    method,
                    methodParams: _.omit(params, ["password"]),
                    maxTime,
                    executionTime: t1 - t0,
                    isPersistent: true
                  })
                );
              }

              resolve(result ? result.kwargs : {});
              return result ? result.kwargs : {};
            },
            e => {
              // if challenge is triggered
              if (e.error === "wamp.quota_exhausted") {
                this.dispatch(
                  trackAction(TRACK_EVENTS.CLIENTERROR, {
                    type: "WAMP_QUOTA_EXHAUSTED",
                    error: e.error,
                    errorParams: e,
                    method,
                    isPersistent: true
                  })
                );

                this.exhaustCollection.push(() => {
                  this.executeCall(method, resolve, reject, params);
                });

                this.webapiCallChallenge(e.kwargs);
              } else if (e.error.includes("wamp.error")) {
                this.dispatch({
                  type: "WAMP_ERROR",
                  payload: {
                    status: "error",
                    error: e.error,
                    params: e,
                    method,
                    methodParams: _.omit(params, ["password"]),
                    isPersistent: true
                  }
                });

                this.dispatch(
                  trackAction(TRACK_EVENTS.CLIENTERROR, {
                    type: "WAMP_ERROR",
                    error: e.error,
                    errorParams: e,
                    method,
                    methodParams: _.omit(params, ["password"]),
                    isPersistent: true
                  })
                );
              } else if (e.error == "wamp.business-error") {
                this.dispatch({
                  type: "BUSINESS_WEBSOCKET_ERROR",
                  payload: {
                    status: "error",
                    error: e.error,
                    params: e,
                    method,
                    methodParams: _.omit(params, ["password"]),
                    isPersistent: true
                  }
                });

                this.dispatch(
                  trackAction(TRACK_EVENTS.CLIENTERROR, {
                    type: "BUSINESS_WEBSOCKET_ERROR",
                    error: e.error,
                    errorParams: e,
                    method,
                    methodParams: _.omit(params, ["password"]),
                    isPersistent: true
                  })
                );

                if (
                  e.kwargs.detail.indexOf("UnauthorizedAccessException") > -1
                ) {
                  resolve();
                  // this.dispatch(checkUserSession("business error"));
                } else {
                  reject(e.kwargs);
                  return e.kwargs;
                }
              } else {
                this.dispatch({
                  type: "UNCAUGHT_WEBSOCKET_ERROR",
                  payload: {
                    status: "error",
                    error: e.error,
                    params: e,
                    method,
                    methodParams: _.omit(params, ["password"]),
                    isPersistent: true
                  }
                });

                this.dispatch(
                  trackAction(TRACK_EVENTS.CLIENTERROR, {
                    type: "UNCAUGHT_WEBSOCKET_ERROR",
                    error: e.error,
                    errorParams: e,
                    method,
                    methodParams: _.omit(params, ["password"]),
                    isPersistent: true
                  })
                );

                reject(e.kwargs);
                return e.kwargs;
              }
            }
          );
      })(this)
    );
  }

  close() {
    return this.Connection.close();
  }

  // register() {
  //   return this.Connection.currentConnection.session.register(...arguments);
  // }

  webapiCallChallenge(err) {
    // it aint pretty to put it here, but it works for now
    // @todo add analytics
    if (!this.quotaIsOpen) {
      this.quotaIsOpen = true;
      // this.dispatch(
      //   openModal("REQUEST_QUOTA_MODAL", {
      //     type: err.type,
      //     extra: { key: err.key },
      //     desc: "You have sent too many requests, please try again later",
      //     onResolve: response => {
      //       this.call("/connection#increaseRequestQuota", {
      //         challengeResponse: response
      //       }).then(
      //         result => {
      //           if (result.success) {
      //             for (let i = 0; i < this.exhaustCollection.length; i++) {
      //               this.exhaustCollection[i]();
      //             }
      //             this.exhaustCollection = [];
      //             this.dispatch(closeModal("REQUEST_QUOTA_MODAL"));
      //             this.quotaIsOpen = false;
      //           } else {
      //             console.log("error", result);
      //           }
      //         },
      //         error => {
      //           console.log("error", error);
      //         }
      //       );
      //     }
      //   })
      // );
    }
    /*
    this.dispatch({
      type: 'SET_WEBAPI_REQUEST_QUOTA',
      payload: {
        requestQuota: true,
        callback: (responseToken) => {
          // in order to continue we just need to resolve the promise with the reCaptcha response token
          this.call('/connection#increaseRequestQuota', {
            challengeResponse: responseToken,
          })
            .then(() => {
              // refresh the page after challenge is done
              // window.location.reload();
              resolve(responseToken);
            })
            .catch(() => {
              reject();
            });
        },
        sitekey: key,
      },
    });
    */
  }
}

const instances = [];
export function resetConnection(instance) {
  if (instances[instance]) {
    instances[instance] = false;
  }
}
export default function AutobahnReact(instance) {
  if (!instances[instance]) {
    instances[instance] = new Autobahn();
  } else {
  }
  return instances[instance];
}
