import AutobahnReact from '../../core/websocket';
import { postCall, getCall, deleteCall } from '../../core/softswiss';

export const getCmsSessionID = () => getCall('/api/player');

export const getSessionInfo = () => getCall('/api/player');

export const getSessions = () => getCall('/api/player/sessions');

export const closeSession = (id) => deleteCall(`/api/player/sessions/${id}`);

export const getIpInfo = () => getCall('/api/current_ip');
