import generateUrls from 'universal-router/generateUrls';
import router from '../router';
import routes from '../routes/routes';
import { domainName } from '../routes/utils/utils';

export const url = generateUrls(router);

export const localeUrl = (to, locale, params) => {
  if (!(to in routes)) {
    throw new Error('Route does not exist in route definition');
  }
  const newLocale = locale === 'en-us' ? 'en-row' : locale;
  return url(to, { path: routes?.[to]?.[newLocale], ...params, locale: newLocale });
};

export const canonicalLocaleUrl = (to, locale, params) => {
  let domain = domainName();
  
  if (!(to in routes)) {
    throw new Error("Route does not exist in route definition");
  }
  let localeurl = url(to, { locale, path: routes[to][locale], ...params });

  return domain+localeurl;
};
