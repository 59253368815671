import fetch from "isomorphic-fetch";
import { isBrowser } from "browser-or-node";
import { getDomainName } from "./utils";
import differenceInSeconds from "date-fns/differenceInSeconds";

const ENDPOINT = __DEV__
  ? APIURL
  : APIURL.indexOf("theclubhousecasino") > -1
  ? isBrowser
    ? `https://backend.${getDomainName(window.location.hostname)}`
    : "https://backend.theclubhousecasino.com"
  : APIURL;
const buildURLQuery = (obj) =>
  Object.entries(obj)
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

export const call = (
  path,
  method,
  params,
  headers = {},
  fallback = "",
  endpoint = ENDPOINT,
  formData = null
) =>
  new Promise((resolve, reject) => {
    const startDate = new Date();
    const url = new URL(`${endpoint}${path}`);
    // console.log("url test", url)
    if (params && method === "GET") {
      Object.keys(params).forEach((key) =>
        url.searchParams.append(key, params[key])
      );
    }

    fetch(url.toString(), {
      method,
      credentials: "include",
      headers: {
        ...headers,
        ...(!formData ? { "Content-Type": "application/json" } : ""),
        Accept: "application/vnd.softswiss.v1+json",
      },
      ...(!formData &&
      params &&
      ["DELETE", "PUT", "POST", "PATCH"].includes(method)
        ? { body: JSON.stringify(params) }
        : ""),
      ...(formData ? { body: formData } : ""),
      redirect: "follow",
    })
      .then(async (response) => {
        const body = await response?.text();
        const responseParams = body && JSON.parse(body);
        if (response.status >= 400) {
          throw responseParams;
        }

        const diff = differenceInSeconds(new Date(), startDate);
        if (diff > 180 && isBrowser) {
          window?.analytics?.track(`Error: Slow SoftSwiss Call`, {
            params,
            path,
            formData,
            diff,
          });
        }
        return resolve(responseParams);
      })
      .catch((err) => {
        if (isBrowser) {
          window?.analytics?.track(`Error: SoftSwiss Call`, {
            err,
            params,
            path,
            formData,
          });
        }
        reject(err);
      });
  });

export const getCall = (path, params, headers, fallback) =>
  call(path, "GET", params, headers, fallback);

export const postCall = (path, params, headers) =>
  call(path, "POST", params, headers);

export const patchCall = (path, params, headers) =>
  call(path, "PATCH", params, headers);

export const putCall = (path, params, headers) =>
  call(path, "PUT", params, headers);

export const uploadCall = (path, formData, headers) =>
  call(path, "POST", {}, headers, "", ENDPOINT, formData);

export const deleteCall = (path, params, headers) =>
  call(path, "DELETE", params, headers);
