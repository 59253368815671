import React, {createContext, useContext, useReducer} from "react";
import {useLazyQuery, useQuery} from "@apollo/client";
import getTopWinnersQuery from "./get-top-winners.graphql";
import getFavoritesQuery from "./get-favorites.graphql";
import addToFavoritesQuery from "./add-to-favorites.graphql";
import removeFromFavoritesQuery from "./remove-from-favorites.graphql";
import {getCmsSessionID} from "../../data/model/session";
import {useDevice} from "../Provide/DeviceContext";
import { useAuth } from '../Provide/AuthContext';

// const GameModal = React.lazy(() => import("../GameModal"));

const GameBrowserContext = createContext({
  loading: true,
  filteredGames: [],
  filterCategory: "",
  filterGroup: "",
  games: [], // @todo, for legacy purpose
  favoriteGames: [],
  topWinners: [],
  allGames: [],
  search: () => {},
  filterByGroup: () => {}
});

const { Provider, Consumer: GameBrowserConsumer } = GameBrowserContext;

const reducer = (
  state,
  { type, component, filterCategory, favoriteGames, filterGroup }
) => {
  switch (type) {
    case "filterByCategory":
      switch (filterCategory) {
        case "POPULARLIVE":
        case "BLACKJACK":
        case "ROULETTE":
        case "BACCARAT":
          const liveGames = state.allGames.filter(
            item => item?.details?.category === filterCategory
          );
          return { ...state, filteredGames: liveGames, filterCategory };

        case "GAMESHOWS":
          const gameShowList = state.allGames.filter(item =>
            [
              "TeenPatii",
              "Monopoly",
              "Holdem",
              "DealNoDeal",
              "SidebetCity",
              "Craps",
              "Crazytime"
            ].includes(item?.details?.vendorCategory)
          );
          return { ...state, filteredGames: gameShowList, filterCategory };
        case "OTHER":
          const otherList = state.allGames.filter(item =>
            ["HOLDEM", "OTHER", "LOTTERY", "POKER", "SICBO"].includes(
              item?.details?.category
            )
          );
          return { ...state, filteredGames: otherList, filterCategory };
        case "LIVE":
          const live = state.allGames.filter(item => item?.details);
          return { ...state, filteredGames: live, filterCategory };
        case "FAVORITES":
          return {
            ...state,
            filteredGames: state.favoriteGames,
            filterCategory
          };
        case "TOPWINGAMES":
          const topWinners = state.allGames.filter(item =>
            state?.topWinners.find(winners => winners.gameId === item.id)
          );
          return { ...state, filteredGames: topWinners, filterCategory };

        case "ALL":
          const sorted = [...state.allGames];
          sorted.sort((a, b) => a.name.localeCompare(b.name));
          return { ...state, filteredGames: sorted, filterCategory };
        default:
          const filteredGames = state.allGames.filter(item =>
            item.categories.items.find(
              categoryItem => categoryItem.id === filterCategory
            )
          );
          return { ...state, filteredGames, filterCategory };
      }

    case "filterByGroup":
      const key = state.isMobile.any
        ? `Mobile$${filterGroup}`
        : `Desktop$${filterGroup}`;
      const filteredGroupGames = state.allGames.filter(item => {
        return !!item?.groupInfos?.items[key];
      });

      filteredGroupGames.sort((a, b) => {
        const [aName, aPos] = a?.groupInfos?.items[key].position.split(".");
        const [bName, bPos] = b?.groupInfos?.items[key].position.split(".");

        return parseInt(aPos) - parseInt(bPos);
      });

      // position
      // default~featured~0001.0001

      return { ...state, filteredGames: filteredGroupGames, filterGroup };

    case "resetFilter":
      return { ...state, filteredGames: state.allGames };
    case "updateFavorites":
      return { ...state, favoriteGames };
    default:
      throw new Error("Unspecified reducer action");
  }
};

const GameBrowserProvider = ({ children, defaultFilter, isMobile }) => {

  const { isAuthenticated } = useAuth();

  const { data: favoriteGames } = useQuery(getFavoritesQuery, {
    variables: {
      playerId: "3620060",
      session: "75f76340-fd8b-44f7-86db-25eda21126ca"
    }
  });

  // const { loading, error, data } = useQuery(getGamesQuery, {
  //   variables: {isDemo: true},
  //   onCompleted: data =>
  //     dispatch({ type: "filterByGroup", filterGroup: "featured" })
  // });
  const data = null;
  const loading = false;
  const {
    loading: topWinnersLoading,
    error: topWinnersError,
    data: topWinnersData
  } = useQuery(getTopWinnersQuery);

  console.log("topWinnersData", topWinnersData);
  const [addToFavorites] = useLazyQuery(addToFavoritesQuery, {
    onCompleted: data =>
      dispatch({ type: "updateFavorites", favoriteGames: data.addToFavorites })
  });
  const [removeFromFavorites] = useLazyQuery(removeFromFavoritesQuery, {
    onCompleted: data =>
      dispatch({
        type: "updateFavorites",
        favoriteGames: data.removeFromFavorites
      })
  });

  // const key = isMobile.any
  //   ? `Mobile$${defaultFilter}`
  //   : `Desktop$${defaultFilter}`;
  // const filteredGroupGames = data?.getGames.filter(item => {
  //   return !!item?.groupInfos?.items[key];
  // });
  //
  // filteredGroupGames?.sort((a, b) => {
  //   const [aName, aPos] = a?.groupInfos?.items[key].position.split(".");
  //   const [bName, bPos] = b?.groupInfos?.items[key].position.split(".");
  //
  //   return parseInt(aPos) - parseInt(bPos);
  // });

  const initialState = {
    isMobile,
    filterGroup: "featured",
    component: null,
    filteredGames: [],
    filterCategory: data?.getGames,
    games: data?.getGames,
    allGames: data?.getGames,
    favoriteGames: favoriteGames?.getFavorites,
    topWinners: topWinnersData?.getTopWinners,
    loading,
    filterByCategory: (filterCategory = "") => {
      dispatch({ type: "filterByCategory", filterCategory });
    },
    filterByGroup: (filterGroup = "") => {
      // const key = isMobile.any
      //   ? `Mobile$${filterGroup}`
      //   : `Desktop$${filterGroup}`;
      // const filteredGroupGames = data?.getGames.filter(item => {
      //   return !!item?.groupInfos?.items[key];
      // });
      //
      // filteredGroupGames?.sort((a, b) => {
      //   const [aName, aPos] = a?.groupInfos?.items[key].position.split(".");
      //   const [bName, bPos] = b?.groupInfos?.items[key].position.split(".");
      //
      //   return parseInt(aPos) - parseInt(bPos);
      // });
      // return filteredGroupGames;
      dispatch({ type: "filterByGroup", filterGroup });
    },
    getFilterByGroup: (filterGroup = "") => {
      const key = isMobile.any
        ? `Mobile$${filterGroup}`
        : `Desktop$${filterGroup}`;
      const filteredGroupGames = data?.getGames.filter(item => {
        return !!item?.groupInfos?.items[key];
      });

      console.log("data?.getGames", data?.getGames);
      filteredGroupGames?.sort((a, b) => {
        const [aName, aPos] = a?.groupInfos?.items[key].position.split(".");
        const [bName, bPos] = b?.groupInfos?.items[key].position.split(".");

        return parseInt(aPos) - parseInt(bPos);
      });
      return filteredGroupGames;
      // dispatch({ type: "filterByGroup", filterGroup });
    },
    resetFilter: () => {
      dispatch({ type: "resetFilter" });
    },
    // search: (query = "", maxResults = 10) => {
    //   if (!query) return [];
    //   const result = data?.getGames.filter(
    //     game =>
    //       game?.name?.toLowerCase()?.includes(query?.toLowerCase()) ||
    //       game?.vendor?.name?.toLowerCase()?.includes(query?.toLowerCase())
    //   );
    //
    //   return result?.slice(0, maxResults);
    // },
    addToFavorites: async id => {
      const result = await getCmsSessionID();

      addToFavorites({
        variables: {
          playerId: "3620060",
          session: result?.cmsSessionID,
          anonymousUserIdentity: "", // the identity found in cookie
          id
        }
      });
    },
    removeFromFavorites: async id => {
      const result = await getCmsSessionID();
      removeFromFavorites({
        variables: {
          playerId: "3620060",
          session: result.cmsSessionID,
          anonymousUserIdentity: "", // the identity found in cookie
          id
        }
      });
    },
    applyNewFilter: () => data?.getGames.filter(game => game.isNew)
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <Provider value={state}>{children}</Provider>;
};

const useGameBrowser = () => useContext(GameBrowserContext);

export { GameBrowserConsumer, GameBrowserProvider, useGameBrowser };
