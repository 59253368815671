import AutobahnReact from '../../core/websocket';
import { getCall, deleteCall, postCall, patchCall } from '../../core/softswiss';

export const getGrantedBonuses = () => getCall('/api/player/bonuses');
export const getGrantedFreespins = () => getCall('/api/player/freespins');

export const getRegistrationBonuses = () => getCall('/api/bonuses/registration?user[profile_attributes][country]=DE');

export const getDepositBonuses = () => getCall('/api/bonuses/deposit');

export const getApplicableBonuses = () => getCall('/api/bonuses/collect');

export const getClaimableBonuses = () => AutobahnReact('everymatrix').call('/user/bonus#getClaimableBonuses');

export const apply = (bonusCode) => AutobahnReact('everymatrix').call('/user/bonus#apply', { bonusCode });

export const forfeitBonus = (id) => deleteCall(`/api/player/bonuses/${id}`);

export const addDepositBonus = (bonusCode) => patchCall(`/api/player/set_bonus_code`,  {deposit_bonus_code: bonusCode});
export const deleteDepositBonus = () => deleteCall('/api/player/clear_bonus_code');

export const activateFreespins = (id) => postCall(`/api/player/freespins/${id}/activation`);
export const forfeitFreespins = (id) => deleteCall(`/api/player/freespins/${id}`);

export const addCouponBonus = (coupon_code) => postCall(`/api/bonuses/coupon`, {coupon_code: coupon_code});