import {EventTypes} from "redux-segment";
import _ from "lodash";
import {TRACK_EVENTS} from "../constants/Analytics";
import {robots, version} from "../config";

/**
 * Track an analytics action
 * @param title
 * @param properties
 * @returns {function(*, *, {AutobahnReact: *})}
 */

const getGroup = user => {
  if (user.roles) {
    switch (true) {
      case user.roles.indexOf("Nurture") > -1:
        return "Nurture";
      case user.roles.indexOf("High Value") > -1:
        return "High Value";
      case user.roles.indexOf("VIP") > -1:
        return "VIP";
      default:
        return "Default";
    }
  }
  return "Default";
};

export function reset() {
  return (dispatch, getState) => {
    dispatch({
      type: "ANALYTICS_RESET",
      payload: {},
      meta: {
        analytics: {
          eventType: EventTypes.reset
        }
      }
    });
  };
}

export function page(name = null, category = null, properties = {}) {
  return (dispatch, getState) => {
    if (
      typeof window !== "undefined" &&
      robots.findIndex(v => window.navigator.userAgent.indexOf(v) > -1) === -1
    ) {
      dispatch({
        type: "ANALYTICS_PAGE",
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.page,
            eventPayload: {
              // options: { integrations: consentList },
              name,
              category,
              properties: _.merge(properties, {
                version,
                stag: getState().affiliate.stage,
                affiliateId: getState().affiliate.id,
                isAuthenticated: getState().auth.isAuthenticated,
                userSegment: getGroup(getState().user)
              })
            }
          }
        }
      });
    }
  };
}

export function identify(playerid = false, properties = {}) {
  return (dispatch, getState) => {
    if (
      typeof window !== "undefined" &&
      robots.findIndex(v => window.navigator.userAgent.indexOf(v) > -1) === -1
    ) {
      dispatch({
        type: "ANALYTICS_IDENTIFY",
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.identify,
            eventPayload: {
              userId: playerid,
              traits: _.merge(properties, {
                Version: version,
                stag: getState().affiliate.stag,
                affiliateId: getState().affiliate.id,
                userSegment: getGroup(getState().user)
              })
            }
          }
        }
      });
    }
  };
}

export function trackAction(title, properties = {}) {
  return (dispatch, getState) => {
    if (
      typeof window !== "undefined" &&
      robots.findIndex(v => window.navigator.userAgent.indexOf(v) > -1) === -1
    ) {
      dispatch({
        type: "ANALYTICS_TRACK",
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.track,
            eventPayload: {
              event: `${TRACK_EVENTS.ACTION}: ${title}`,
              properties: _.merge(properties, {
                version,
                stag: getState()?.affiliate?.stag,
                affiliateId: getState()?.affiliate?.id,
                isAuthenticated: getState()?.auth?.isAuthenticated,
                //userSegment: getGroup(getState().user)
              })
            }
          }
        }
      });
    }
  };
}

export function trackClick(title, properties = {}) {
  return (dispatch, getState) => {
    if (
      typeof window !== "undefined" &&
      robots.findIndex(v => window.navigator.userAgent.indexOf(v) > -1) === -1
    ) {
      dispatch({
        type: "ANALYTICS_CLICK",
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.track,
            eventPayload: {
              event: `${TRACK_EVENTS.CLICK}: ${title}`,
              properties: _.merge(properties, {
                version,
                stag: getState().affiliate.stag,
                affiliateId: getState().affiliate.id,
                isAuthenticated: getState().auth.isAuthenticated,
                userSegment: getGroup(getState().user)
              })
            }
          }
        }
      });
    }
  };
}
