import promotions from "./promotions/promotions-overview.routes.json";
import landing from "./landing/landing-overview.routes.json";
import landingPageDetail from "./landing/landing-detail.routes.json";
import promotionDetail from "./promotions/promotion-detail.routes.json";
import home from "./home/home.routes.json";
import myClubhouse from "./myClubhouse/myClubhouse.routes.json";
import myClubhouse3 from "./myClubhouse/myClubhouse.routes.json";
import freeSpinGames from "./freeSpinGames/freeSpinGames.routes.json";
import depositStatus from "./deposit/deposit-status.routes.json";
import deposit from "./deposit/deposit.routes.json";
import faq from "./faq/faq.routes.json";
import forgotPassword from "./forgot-password/forgot-password.routes.json";
import removeLimit from "./remove-limit/remove-limit.routes.json";
import touch from "./game/touch-index.routes.json";
import liveGame from "./game/live-index.routes.json";
import game from "./game/game.routes.json";
import profile from "./account/profile/profile.routes.json";
import responsibleGamingVerify from "./account/responsible-gaming/responsible-gaming-verify.routes.json";
import renewEmail from "./account/renew-email/renew-email.routes.json";
import aboutUs from "./legal/about-us/about-us.routes.json";
import bonusTerms from "./legal/bonus-terms/bonus-terms.routes.json";
import privacyPolicy from "./legal/privacy-policy/privacy-policy.routes.json";
import tradingPolicy from "./legal/trading-policy/trading-policy.routes.json";
import responsibleGamingLegal from "./legal/responsible-gaming/responsible-gaming.routes.json";
import gameRules from "./legal/game-rules/game-rules.routes.json";
import termsAndConditions from "./legal/privacy-policy/privacy-policy.routes.json";
import cookiePolicy from "./legal/cookie-policy/cookie-policy.routes.json";
import liveLobby from "./liveLobby/live-lobby.routes.json";
import confirmation from "./account/confirmation/confirmation.routes.json";
import register from "./register/register.routes.json";
import payments from "./payments/payments.routes.json";
import loyalty from "./loyalty/loyalty.routes.json";
import cryptoLobby from "./cryptoLobby/crypto-lobby.routes.json";
import tournaments from "./tournaments/tournaments-overview.routes.json";
import tournamentDetail from "./tournaments/tournament-detail.routes.json";
import welcome from "./welcome/welcome.routes.json";

export default {
  promotions,
  promotionDetail,
  home,
  myClubhouse,
  myClubhouse3,
  freeSpinGames,
  lobby: home,
  login: home,
  register,
  payments,
  confirmation,
  responsibleGamingVerify,
  depositStatus,
  deposit,
  faq,
  forgotPassword,
  touch,
  liveGame,
  game,
  profile,
  renewEmail,
  bonusTerms,
  aboutUs,
  privacyPolicy,
  termsAndConditions,
  cookiePolicy,
  tradingPolicy,
  gameRules,
  responsibleGamingLegal,
  liveLobby,
  cryptoLobby,
  loyalty,
  removeLimit,
  tournamentDetail,
  tournaments,
  welcome,
  landing,
  landingPageDetail,
};
